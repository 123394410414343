// src/App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./Pages/Home";
import Signin from "./Pages/Signin";
import Signup from "./Pages/Signup";
import Newhome from "./Pages/Newhome";
import Contact from "./Pages/Contact";
import Admindashboard from "./admin/adminpages/Admindashboard";
import Adminsignup from "./admin/adminpages/Adminsignup";
import Verification from "./Pages/Verification";
import { StepContext } from "./StepContext";
import { StepContextAdmin } from "./StepContextAdmin";
import Profile from "./Pages/profile";
import ForgotPassword from "./Pages/ForgotPassword";
import Changepassword from "./Pages/Changepassword";
import SignInQuestions from "./Pages/SignInQuestions";
import Verificationforgot from "./Pages/Verificationforgot";
import Selling from "./Pages/Selling";
import Buying from "./Pages/Buying"
import ImagePage from "./Pages/ImagePage";
import Renting from "./Pages/Renting";
import Message from "./Pages/Message"
function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/signin" element={<Signin />} />
        {/* <Route exact path="/profile" element={<Profile />} /> */}
        <Route exact path="/profile" element={<Profile />}/>
        <Route exact path="/admindashboard" element={<Admindashboard />} />
        <Route exact path="/forgotpassword" element={<ForgotPassword />} />
        <Route exact path="/selling" element={<Selling />} />
        <Route exact path="/buying" element={<Buying />} />
        <Route path="/image/:id" element={<ImagePage />} />
        <Route exact path="/rent" element={<Renting />} />
        <Route
          exact
          path="/admin"
          element={
            <StepContextAdmin>
              <Adminsignup />
            </StepContextAdmin>
          }
        />
        <Route
          exact
          path="/signup"
          element={
            <StepContext>
              <Signup />
            </StepContext>
          }
        />
        <Route exact path="/newhome" element={<Newhome />} />
        <Route exact path="/contact" element={<Contact />} />
        <Route exact path="/Verification" element={<Verification />} />
        <Route exact path="/Changepassword" element={<Changepassword />} />
        <Route exact path="/SignInQuestions" element={<SignInQuestions />} />
        <Route exact path="/Verificationforgot" element={<Verificationforgot />} />
        <Route exact path="/Message" element={<Message />} />

      </Routes>
    </Router>
  );
}

export default App;
