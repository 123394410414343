// src/StepContextAdmin.js
import React, { createContext, useState } from "react";

export const multiStepContextAdmin = createContext();

export const StepContextAdmin = ({ children }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [userData, setUserData] = useState({});
  const [finalData, setFinalData] = useState([]);

  return (
    <multiStepContextAdmin.Provider
      value={{
        currentStep,
        setCurrentStep,
        userData,
        setUserData,
        finalData,
        setFinalData,
      }}
    >
      {children}
    </multiStepContextAdmin.Provider>
  );
};
