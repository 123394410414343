import React from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  IconButton,
  Box,
} from "@mui/material";
import HomeIcon from "@mui/icons-material/Home";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';

const drawerWidth = 300;

const Sidebar = ({ open, toggleDrawer, activeMenu, handleMenuClick }) => {
  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? drawerWidth : 64,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: open ? drawerWidth : 64,
          boxSizing: "border-box",
          transition: "width 0.3s",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: open ? "flex-end" : "center",
          padding: "8px",
        }}
      >
        <IconButton onClick={toggleDrawer}>
          {open ? <ChevronLeftIcon /> : <MenuIcon />}
        </IconButton>
      </Box>
      <Divider />
      <List sx={{ paddingTop: "27px" }}>
        {[
          { text: "Latest Property", icon: <HomeIcon /> },
          { text: "International Homes", icon: <HomeIcon /> },
          { text: "Dream Homes", icon: <HomeIcon /> },
          { text: "New Developments", icon: <HomeIcon /> },
          { text: "All Users", icon: <AccountCircleRoundedIcon /> },
          { text: "Logout", icon: <ExitToAppIcon /> },
        ].map((item) => (
          <ListItem
            button
            key={item.text}
            onClick={() => handleMenuClick(item.text)}
            selected={activeMenu === item.text}
          >
            <ListItemIcon>{item.icon}</ListItemIcon>
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
};

export default Sidebar;
