import React, { useContext, useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import "../styles/Verification.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { useState } from "react";
import { multiStepContext } from "../StepContext";

const Verification = () => {
  const { userData, setUserData } = useContext(multiStepContext);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const inputs = document.querySelectorAll(".checkcode");
    inputs.forEach(function (input, index) {
      input.addEventListener("input", function () {
        if (index < inputs.length - 1) {
          inputs[index + 1].focus();
        }
      });
    });
  }, []);

  const handleVerify = () => {
    const { firstdigit, seconddigit, thirddigit, fourthdigit, fifthdigit, sixthdigit } = userData;

    if (!firstdigit || !seconddigit || !thirddigit || !fourthdigit || !fifthdigit || !sixthdigit) {
      setError("Please enter a valid OTP code.");
      return;
    }

    setError("");

    fetch("http://localhost:9000/api/verifyotponemail", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("OTP does not match. Please enter a valid code.");
        }
        return response.json();
      })
      .then((data) => {
        toast.success(
          "Account Verification Successfully Done. Welcome to Ekonnect.com!",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );

        setTimeout(() => {
          navigate("/signin");
        }, 3000);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div id="signin">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div className="signup">
              <h3 style={{ marginBottom: "50px" }}>Enter Verification Code</h3>
              <ToastContainer />
              <div className="otp-field" style={{ textAlign: "center" }}>
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="firstdigit"
                  value={userData.firstdigit}
                  name="firstdigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      firstdigit: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="seconddigit"
                  value={userData.seconddigit}
                  name="seconddigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      seconddigit: e.target.value,
                    }))
                  }
                />
                <input
                  class="space"
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="thirddigit"
                  value={userData.thirddigit}
                  name="thirddigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      thirddigit: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="fourthdigit"
                  value={userData.fourthdigit}
                  name="fourthdigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      fourthdigit: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="fifthdigit"
                  value={userData.fifthdigit}
                  name="fifthdigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      fifthdigit: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="sixthdigit"
                  value={userData.sixthdigit}
                  name="sixthdigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      sixthdigit: e.target.value,
                    }))
                  }
                />
              </div>

              {error && (
                <p className="error" style={{ color: "red" }}>
                  {error}
                </p>
              )}

              <Button
                id="sbbtnv"
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleVerify}
              >
                Verify
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Verification;
