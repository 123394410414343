import React, { useState, useRef } from "react";
import "../styles/Verification.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

const Verification = () => {
  const [firstDigit, setFirstDigit] = useState("");
  const [secondDigit, setSecondDigit] = useState("");
  const [thirdDigit, setThirdDigit] = useState("");
  const [fourthDigit, setFourthDigit] = useState("");
  const [fifthDigit, setFifthDigit] = useState("");
  const [sixthDigit, setSixthDigit] = useState("");

  const firstDigitRef = useRef(null);
  const secondDigitRef = useRef(null);
  const thirdDigitRef = useRef(null);
  const fourthDigitRef = useRef(null);
  const fifthDigitRef = useRef(null);
  const sixthDigitRef = useRef(null);

  const navigate = useNavigate();

  const handleChange = (setter, nextRef) => (e) => {
    setter(e.target.value);
    if (e.target.value.length === 1 && nextRef && nextRef.current) {
      nextRef.current.focus();
    }
  };

  const handleVerify = async () => {
    const otp = `${firstDigit}${secondDigit}${thirdDigit}${fourthDigit}${fifthDigit}${sixthDigit}`;
    try {
      const response = await fetch("http://localhost:9000/api/verifyotp", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ otp })
      });

      if (response.ok) {
        toast.success("OTP verified successfully!");
        navigate("/profile");
      } else {
        toast.error("OTP verification failed. Please try again.");
      }
    } catch (error) {
      toast.error("An error occurred during OTP verification. Please try again.");
    }
  };

  return (
    <div id="signin">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div className="signup">
              <h3 style={{ marginBottom: "50px" }}>Enter Verification Code</h3>
              <ToastContainer />
              <div className="otp-field" style={{ textAlign: "center" }}>
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  value={firstDigit}
                  onChange={handleChange(setFirstDigit, secondDigitRef)}
                  ref={firstDigitRef}
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  value={secondDigit}
                  onChange={handleChange(setSecondDigit, thirdDigitRef)}
                  ref={secondDigitRef}
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  value={thirdDigit}
                  onChange={handleChange(setThirdDigit, fourthDigitRef)}
                  ref={thirdDigitRef}
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  value={fourthDigit}
                  onChange={handleChange(setFourthDigit, fifthDigitRef)}
                  ref={fourthDigitRef}
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  value={fifthDigit}
                  onChange={handleChange(setFifthDigit, sixthDigitRef)}
                  ref={fifthDigitRef}
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  value={sixthDigit}
                  onChange={handleChange(setSixthDigit, null)}
                  ref={sixthDigitRef}
                />
              </div>
              <Button
                id="sbbtnv"
                type="button"
                fullWidth
                variant="contained"
                onClick={handleVerify}
              >
                Verify
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Verification;