import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Signup.css";

const questions = [
  "What is your Favourite Food?",
  "What is your Favourite Drink?",
  "What is your Favourite Colour?",
  "What is your Favourite Sport?",
  "Who is your Favourite Super Hero?",
  "What Year would you like to be Born?",
  "What Country of the World would you like to Live?",
  "What Planet in the Cosmos would you like to Visit?",
  "What Super Powers would you like to Have?",
  "If you had 1 Wish, what Wish would that Be?"
];

const SignInQuestions = () => {
  const navigate = useNavigate(); // Initialize navigate function
  const location = useLocation();
  const { email } = location.state || {};
  const [answers, setAnswers] = useState({
    answer1: { question: questions[0], answer: "" },
    answer2: { question: questions[1], answer: "" },
    answer3: { question: questions[2], answer: "" },
    answer4: { question: questions[3], answer: "" },
    answer5: { question: questions[4], answer: "" },
    answer6: { question: questions[5], answer: "" },
    answer7: { question: questions[6], answer: "" },
    answer8: { question: questions[7], answer: "" },
    answer9: { question: questions[8], answer: "" },
    answer10: { question: questions[9], answer: "" }
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [name]: { ...prevAnswers[name], answer: value }
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const filledAnswers = Object.values(answers).filter(
      (answer) => answer.answer.trim() !== ""
    );
    if (filledAnswers.length < 5) {
      toast.error("Please answer at least five questions.");
      return;
    }

    try {
      const dataToSend = {
        ...answers,
        email: email
      };

      const response = await fetch("http://localhost:9000/api/signinquestions", {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(dataToSend)
      });

      if (response.status === 200) {
        toast.success("Questions filled successfully", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

        // Redirect to verification page
        navigate("/Verification");
      } else {
        toast.error("Failed to submit security questions.", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    } catch (error) {
      toast.error("An error occurred while submitting security questions.");
    }
  };

  return (
    <div id="signup">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>

        <ToastContainer />

        <Grid container spacing={2}>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={10}>
            <div className="quespg">
              <h3>Security Questions</h3>
              <p>Answer at least five questions among these 10.</p>
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {Object.keys(answers).map((key, index) => (
                    <Grid item xs={12} md={6} key={index}>
                      <TextField
                        id={key}
                        label={answers[key].question}
                        variant="outlined"
                        margin="normal"
                        name={key}
                        fullWidth
                        value={answers[key].answer} // Display the answer only after submission
                        onChange={handleChange}
                      />
                    </Grid>
                  ))}
                </Grid>

                <div className="back_btn1">
                  <Button id="sbbtn2" type="submit" variant="contained">
                    Submit
                  </Button>
                </div>
              </form>
            </div>
          </Grid>

          <Grid item xs={12} md={1}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default SignInQuestions;