import React from 'react';
import { IoIosBed } from "react-icons/io";
import { TbBathFilled } from "react-icons/tb";
import { FaCar } from "react-icons/fa6";

const Dreamcard = (props) => {
    const { imageUrl, image1, image2, image3, heading, description ,Bedrooms,Washrooms, Parkings} = props;
  
    return (
      <div>
        <div className="res_sldr1">
          <img src={imageUrl} alt="" />
          <div className="tke_out">
            <h6 className="hsb">
              {heading}
              <span>.NSW</span>
            </h6>
            <div className="flx_svg">
              <div className="hmestt">
              <IoIosBed size={19}/>
                <span>{Bedrooms}</span>
              </div>
              <div className="hmestt">
              <TbBathFilled  size={19} />
                <span>{Washrooms}</span>
              </div>
              <div className="hmestt">
              <FaCar  size={18}/>
                <span>{Parkings}</span>
              </div>
            </div>
            <p>{description}</p>
          </div>
        </div>
      </div>
    );
  };

 export default Dreamcard;