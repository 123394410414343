import React, { useEffect, useState } from "react";
import { Box, Button, Modal, TextField, Typography, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import axios from "axios";

const Allusers = () => {
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false); // State for modal open/close

  // State variables for form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [roles, setRoles] = useState("");
  const [password, setPassword] = useState("");

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const data = [
    'Customer', 'Buyer', 'Seller', 'Agent', 'Buyer Agent', 'Builders', 'Brick Layers',
    'Electricians', 'Plumber', 'Mortgage Broker', 'Lawyer', 'Accountant', 'Financial Advisor',
    'Builder', 'Building Engineer', 'Architect', 'Pest Control Inspector', 'Soil Technician'
  ];

  const fetchUsers = async () => {
    try {
      const response = await axios.get('http://localhost:9000/api/admin/getallusers'); // Replace with your API endpoint
      setRows(response.data);
    } catch (error) {
      console.error("Error fetching users data:", error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  // Function to handle form submission
  const handleSubmit = async () => {
    const newUser = {
      firstname: firstName,
      lastname: lastName,
      email: email,
      roles: roles,
      password: password
    };

    try {
      const response = await axios.post('http://localhost:9000/api/admin/adduser', newUser); // Replace with your API endpoint for adding user

      console.log("User added successfully:", response.data);
      handleClose(); // Close the modal after successful submission
      fetchUsers(); // Fetch users again to update the list after adding a new user
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const handleDelete = async (userId) => {
    try {
      const response = await fetch(`http://localhost:9000/api/deleteuser/${userId}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete user');
      }
  
      console.log("User deleted successfully");
      fetchUsers(); // Fetch users again to update the list after deletion
    } catch (error) {
      console.error("Error deleting user:", error);
    }
  };

  const columns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "firstname", headerName: "First Name", width: 150 },
    { field: "lastname", headerName: "Last Name", width: 150 },
    { field: "email", headerName: "Email", width: 250 },
    { field: "roles", headerName: "Roles", width: 150 },
    { field: "password", headerName: "Password", width: 150 },
    {
      field: "Add",
      headerName: "Add New User",
      width: 150,
      renderCell: (params) => (
        <Button variant="contained" color="success" onClick={handleOpen}>
          Add New
        </Button>
      )
    },
    {
      field: "Remove",
      headerName: "Remove User",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="error"
          onClick={() => handleDelete(params.row._id)}
        >
          Remove
        </Button>
      )
    },
  ];

  return (
    <Box sx={{ width: "100%", height: 400 }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5]}
        checkboxSelection
        getRowId={(row) => row._id} // Specify the unique identifier
      />
      {/* Modal for adding a new user */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Add New User
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="First Name"
                variant="outlined"
                fullWidth
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Last Name"
                variant="outlined"
                fullWidth
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Email"
                variant="outlined"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Password"
                variant="outlined"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                select
                label="Roles"
                variant="outlined"
                fullWidth
                value={roles}
                onChange={(e) => setRoles(e.target.value)}
                SelectProps={{ native: true }}
                placeholder="Select Role"
              >
                <option value=""></option>
                {data.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </Box>
  );
};

export default Allusers;