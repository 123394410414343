import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from "../components/Footer";
import { useNavigate } from 'react-router-dom';
import { FiMessageCircle } from "react-icons/fi";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  Button,
  Divider,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FiShoppingCart } from "react-icons/fi";
import HomeIcon from '@mui/icons-material/Home';
import { MdSell } from "react-icons/md";
import axios from 'axios';  // Import Axios for API calls
import 'react-toastify/dist/ReactToastify.css';

const Message = () => {
  const [activeMenu, setActiveMenu] = useState("Message");
  const [chats, setChats] = useState([]);  // Initialize chats as an empty array
  const [activeChat, setActiveChat] = useState(null);
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const userId = sessionStorage.getItem("userId");
  const navigate = useNavigate();

  const handleMenuClick = (menu) => {
    if (activeMenu === menu) return; // Avoid unnecessary navigation

    setActiveMenu(menu);
    if (menu === "Buying") {
      navigate('/buying');
    } else if (menu === "Renting") {
      navigate('/rent');
    } else if (menu === "Selling") {
      navigate('/selling');
    }
    else if (menu === "Profile") {
      navigate('/profile');
    }
    else if (menu === "Message") {
      navigate('/message');
    }
  };
  // Fetching data when the component mounts
  useEffect(() => {
    const fetchChats = async () => {
      try {
        if (!userId) {
          console.error("User not logged in");
          return;
        }

        setLoading(true); // Set loading to true before API call

        const response = await axios.get(`http://localhost:9000/api/getbyid/${userId}`);
        const data = response.data;

        console.log('Fetched data:', data); // Log the fetched data to inspect its structure

        // Assuming 'senders' is the correct array
        if (Array.isArray(data.senders)) {
          console.log('Senders:', data.senders);  // Log the senders to inspect the structure
          setChats(data.senders);  // Set chats from the 'senders' array
        } else {
          console.error("Received data does not contain 'senders' property or it's not an array:", data);
          setError('Invalid data format');
        }
      } catch (err) {
        setError('Error fetching chats');
        console.error(err);
      } finally {
        setLoading(false); // Set loading to false after API call
      }
    };

    fetchChats();  // Call the fetchChats function
  }, []);

  // Handle selecting a chat
  const selectChat = (chat) => {
    const updatedChats = chats.map(c => {
      if (c._id === chat._id) {
        return {
          ...c,
          unreadMessages: 0, // Mark all unread messages as read
        };
      }
      return c;
    });

    setChats(updatedChats);
    setActiveChat(chat); // Set the selected chat as active
  };

  // Send message to the active chat
  const sendMessage = () => {
    if (activeChat && message.trim() !== '') {
      const newMessage = { text: message, sender: "me" };

      const updatedChats = chats.map(chat => {
        if (chat._id === activeChat._id) {
          return {
            ...chat,
            messages: [...chat.messages, newMessage],
            lastMessage: message,
            unreadMessages: 0,
          };
        }
        return chat;
      });

      setChats(updatedChats);
      setActiveChat(prevActiveChat => ({
        ...prevActiveChat,
        messages: [...prevActiveChat.messages, newMessage]
      }));

      setMessage('');
    }
  };

  // Handle key press event to send message
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();  // Prevent newline in the text field
      sendMessage();  // Trigger sendMessage when Enter key is pressed
    }
  };

  return (
    <div>
      <Header />
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Box
          sx={{
            width: "321px",
            borderRight: "1px solid #ddd",
            padding: "20px",
          }}
        >
          <Typography variant="h6" gutterBottom>Menu</Typography>
          <List
            component="nav"
            sx={{
              padding: "0",
              backgroundColor: "#fff",
              boxShadow: "0 2px 4px 0 rgba(0,0,0,.1)",
            }}
          >
            {/* Menu Items */}
            <ListItem button
              className={`menu-item ${activeMenu === "Buying" ? "active" : ""}`}
              onClick={() => handleMenuClick("Buying")}
            >
              <ListItemIcon>
                <FiShoppingCart />
              </ListItemIcon>
              <ListItemText
                primary="Buying"
                primaryTypographyProps={{
                  sx: {
                    color: activeMenu === "Buying" ? "#000" : "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem button
              className={`menu-item ${activeMenu === "Renting" ? "active" : ""}`}
              onClick={() => handleMenuClick("Renting")}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Renting"
                primaryTypographyProps={{
                  sx: {
                    color: activeMenu === "Renting" ? "#000" : "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem button
              className={`menu-item ${activeMenu === "Selling" ? "active" : ""}`}
              onClick={() => handleMenuClick("Selling")}
            >
              <ListItemIcon>
                <MdSell />
              </ListItemIcon>
              <ListItemText
                primary="Selling"
                primaryTypographyProps={{
                  sx: {
                    color: activeMenu === "Selling" ? "#000" : "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem button
              className={`menu-item ${activeMenu === "Profile Settings" ? "active" : ""}`}
              onClick={() => handleMenuClick("Profile Settings")}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Profile Settings"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Message" ? "active" : ""}`}
  onClick={() => handleMenuClick("Message")}
>
              <ListItemIcon>
              <FiMessageCircle />
              </ListItemIcon>
              <ListItemText
                primary="Message"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: " 10px 0",
                  },
                }}
              />
            </ListItem>
          </List>
        </Box>

        {/* Chat Section */}
        <Box sx={{ display: "flex", flex: 1, padding: "20px" }}>
          {/* Left Sidebar (Contacts / Conversation List) */}
          <Box sx={{ width: "320px", borderRight: "1px solid #ddd", padding: "20px" }}>
            <Typography variant="h6" gutterBottom>Conversations</Typography>
            <List component="nav" sx={{ padding: "0", backgroundColor: "#fff", boxShadow: "0 2px 4px 0 rgba(0,0,0,.1)" }}>
              {loading ? (
                <Typography variant="h6" sx={{ textAlign: "center" }}>Loading chats...</Typography>
              ) : error ? (
                <Typography variant="h6" sx={{ textAlign: "center", color: "red" }}>{error}</Typography>
              ) : (
                Array.isArray(chats) && chats.length > 0 ? (
                  chats.map((chat) => (
                    <ListItem button key={chat._id} onClick={() => selectChat(chat)} sx={{ borderBottom: "1.5px solid #cacaca", borderRadius: "10px" }}>
                      {/* <ListItemIcon>
                        <CloudUploadIcon sx={{ color: "#388E3C" }} />
                      </ListItemIcon> */}
                      <ListItemText
                        primary={`${chat.firstname || 'No name'} `} 
                        secondary={
                          chat.messages && chat.messages.length > 0
                            ? chat.messages[chat.messages.length - 1].text
                            : ""
                        }
                      />
                    </ListItem>
                  ))
                ) : (
                  <Typography variant="h6" sx={{ textAlign: "center" }}>No chats available</Typography>
                )
              )}
            </List>
          </Box>

          {/* Right Section (Chat Window) */}
          <Box sx={{ flex: 1, padding: "10px" }}>
            {activeChat ? (
              <>
                {/* Display both firstname and _id of the active chat */}
                <Typography variant="h5" sx={{ padding: "20px", boxShadow: "0px 3px 6px 0px #e7e7e7", borderRadius: "18px" }} gutterBottom>
                  Chat with {activeChat.firstname || 'No name'} (ID: {activeChat._id || 'N/A'}) {/* Display both firstname and _id */}
                </Typography>

                <Box sx={{ maxHeight: "60vh", overflowY: "auto", marginBottom: "10px", padding: "20px" }}>
                  {activeChat.messages.map((msg, idx) => (
                    <Box key={idx} sx={{
                      padding: "10px",
                      marginBottom: "10px",
                      backgroundColor: msg.sender === "me" ? "#d1f8e4" : "#f1f1f1",
                      borderRadius: "8px",
                      textAlign: msg.sender === "me" ? "right" : "left",
                      marginLeft: msg.sender === "me" ? "auto" : "0",
                      marginRight: msg.sender === "me" ? "0" : "auto",
                      width: "fit-content",
                      maxWidth: "70%",
                      wordBreak: "break-word",
                      whiteSpace: "normal",
                    }}>
                      <Typography>{msg.text}</Typography>
                    </Box>
                  ))}
                </Box>

                {/* Chat Input Section */}
                <Box sx={{ display: "flex", gap: 2 }}>
                  <TextField
                    label="Type a message"
                    variant="outlined"
                    fullWidth
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <Button variant="contained" color="primary" onClick={sendMessage}>Send</Button>
                </Box>
              </>
            ) : (
              <Typography variant="h6">Select a conversation to start chatting.</Typography>
            )}
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Message;
