// src/admin/adminpages/Adminsignup.js
import React, { useContext } from "react";
import { Stepper, StepLabel, Step } from "@mui/material";
import Adminlogin from "../admincomponents/Adminlogin";
import Adminquestions from "../admincomponents/Adminquestions";
import Admincustomques from "../admincomponents/Admincustomques";
import Adminverify from "../admincomponents/Adminverify";
import { multiStepContextAdmin } from "../../StepContextAdmin";

const Adminsignup = () => {
  const { currentStep } = useContext(multiStepContextAdmin);

  function showStep(step) {
    switch (step) {
      case 1:
        return <Adminlogin />;
      case 2:
        return <Adminquestions />;
      case 3:
        return <Admincustomques />;
      case 4:
        return <Adminverify />;
      default:
        return <Adminlogin />;
    }
  }

  return (
    <div className="App">
      <header className="App-header">
        <div className="center-stepper">
          <Stepper
            style={{ display: "none" }}
            activeStep={currentStep - 1}
            orientation="horizontal"
          >
            <Step>
              <StepLabel>First Step</StepLabel>
            </Step>
            <Step>
              <StepLabel>Second Step</StepLabel>
            </Step>
            <Step>
              <StepLabel>Third Step</StepLabel>
            </Step>
            <Step>
              <StepLabel>Fourth Step</StepLabel>
            </Step>
          </Stepper>
        </div>
        {showStep(currentStep)}
      </header>
    </div>
  );
};

export default Adminsignup;
