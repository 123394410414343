import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import "../styles/Header.css";
import CustomDrawer from "./CustomDrawer";
import Currencydropdown from "./Currencydropdown";
import { AppBar, Toolbar, Typography, Container, Grid } from "@mui/material";

const Header = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Check if user is logged in by looking at sessionStorage
    const userEmail = sessionStorage.getItem('userEmail');
    if (userEmail) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  }, []); // Empty dependency array means this runs once when the component mounts

  const handleSignOut = () => {
    // Clear session storage and update the state
    sessionStorage.clear();
    setIsLoggedIn(false);
    navigate("/Signin"); // Redirect to home or another page
  };

  return (
    <AppBar position="static" className="AppBar">
      <Container>
        <Toolbar className="Toolbar" id="main_head">
          <Grid container alignItems="center" justifyContent="space-between">
            <Grid item className="logo-wdth">
              <Typography variant="h6" className="Logo">
                <Link to="/" className="lnks">
                  <img src={logo} alt="Logo" />
                </Link>
              </Typography>
            </Grid>
            <Grid item id="crncy">
              <Grid container spacing={2} className="MenuItems">
                <Grid item>
                  <Currencydropdown />
                </Grid>
              </Grid>
            </Grid>
            <Grid item id="lgn-sgnm">
              <Grid container spacing={2} className="MenuItems" id="lgn-sgn">
                {isLoggedIn ? (
                  <Grid item>
                    <Typography variant="h6" className="MenuItem" onClick={handleSignOut}>
                      Sign Out
                    </Typography>
                  </Grid>
                ) : (
                  <>
                    <Grid item>
                      <Typography variant="h6" className="MenuItem">
                        <Link to="/signin" className="lnks">
                          Sign In
                        </Link>
                      </Typography>
                    </Grid>
                    <Grid item>
                      <Typography variant="h6" className="MenuItem">
                        <Link to="/signup" className="lnks">
                          Sign Up
                        </Link>
                      </Typography>
                    </Grid>
                  </>
                )}
                <Grid item className="dewr">
                  <Typography variant="h6" className="MenuItem">
                    <CustomDrawer />
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default Header;
