import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import HorizontalSplitIcon from "@mui/icons-material/HorizontalSplit";
import { Link } from "react-router-dom";
import "../styles/Drawer.css";

export default function CustomDrawer() {
  const [state, setState] = React.useState({
    left: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const menuItems = [
    { text: "Buy", path: "/buying" },
    { text: "Rent", path: "/buying" },
    { text: "Sold", path: "/sold" },
    { text: "New Homes", path: "/newhome" },
    { text: "Find Agents", path: "/find-agents" },
    { text: "Home Loans", path: "/home-loans" },
    { text: "News", path: "/news" },
    { text: "Commercial", path: "/commercial" },
    { text: "Test", path: "/test" },
  ];

  return (
    <div>
      <Button onClick={toggleDrawer("left", true)}>
        <HorizontalSplitIcon id="fileicon" />
      </Button>
      <Drawer
        anchor="left"
        open={state["left"]}
        onClose={toggleDrawer("left", false)}
        PaperProps={{ id: "custom-drawer-id" }}
      >
        <Box
          sx={{ width: "auto" }}
          role="presentation"
          onClick={toggleDrawer("left", false)}
          onKeyDown={toggleDrawer("left", false)}
        >
          <List id="hhhhhhh">
            {menuItems.map(({ text, path }) => (
              <ListItem key={text} disablePadding sx={{ paddingLeft: "40px" }}>
                <ListItemButton>
                  <Link to={path} className="customClass">
                    <ListItemText primary={text} />
                  </Link>
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
}
