import React from 'react';

const Newcard = ({imageUrl,heading,description}) =>{

    return (
         <div className="responsive_flx" id="nw_dvlpmnt">
	   			<div className="res_sldr2">
			<img src={imageUrl}  alt="" />
				<div className="overlaydrm"></div>
				<div className="tke_out10" id="nw_dvlpmnts">	
					<h6>{heading}</h6>
					<p>{description}</p>
				</div>				
			</div>
        </div>
    );
}

export default Newcard;