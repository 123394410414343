import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import "../styles/Homelisting.css";
import Homelistcard from "./Homelistcard";

const Homelisting = () => {
  const [homeListings, setHomeListings] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("http://localhost:9000/api/getinternationaldisplayhomelisting");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        // Ensure the data is correctly mapped
        const formattedData = data.map(item => ({
          imageUrl: `http://localhost:9000/${item.image}`,
          heading: item.Title,
          Address: item.Address,
          Bedrooms: item.Bedrooms,
          Washrooms: item.Washrooms, // Correct field mapping
          Parkings: item.Parkings // Correct field mapping
        }));
        setHomeListings(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Container className="homelist">
        <h2>International Display Homes Listings</h2>
      </Container>
      <Container>
        <Grid container spacing={3}>
          {homeListings.map((listing, index) => (
            <Grid key={index} item xs={12} md={4}>
              <Homelistcard
                imageUrl={listing.imageUrl}
                heading={listing.heading}
                description={listing.Address}
                Bedrooms={listing.Bedrooms}
                Washrooms={listing.Washrooms}
                Parkings={listing.Parkings}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Homelisting;
