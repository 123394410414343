import React, { useContext, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/Signup.css";
import "../adminstyle/adminsignin.css";
import { multiStepContextAdmin } from "../../StepContextAdmin";

const Admincustomques = () => {
  const { setCurrentStep, userData, setUserData } = useContext(
    multiStepContextAdmin
  );
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const validateCustomQuestions = () => {
    // Check if all five questions and answers are filled
    const answeredQuestions = Object.keys(userData).filter(key => key.startsWith('ques')).length;
    const answeredAnswers = Object.keys(userData).filter(key => key.startsWith('ans')).length;
  
    if (answeredQuestions === 5 && answeredAnswers === 5) {
      setError("");
      const data = {
        adminEmail: userData.adminEmail,
        answer1: { question: userData.ques1, answer: userData.ans1 },
        answer2: { question: userData.ques2, answer: userData.ans2 },
        answer3: { question: userData.ques3, answer: userData.ans3 },
        answer4: { question: userData.ques4, answer: userData.ans4 },
        answer5: { question: userData.ques5, answer: userData.ans5 },
      };
  
      console.log('Data being sent:', data);
  
      fetch("http://localhost:9000/api/admin/customquestion", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(
              "Signup Successful.. A Verification Code to verify your Account has been sent to your registered email address.!!",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
  
            setTimeout(() => {
              setCurrentStep(4); // Proceed to the next step
            }, 3000);
          } else if (response.status === 409) {
            toast.error("Email is already used.. Please try again.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
  
            setTimeout(() => {
              navigate("/signup");
            }, 5000);
          } else {
            // Handle other response statuses
            return response.json().then((error) => {
              console.log(error);
            });
          }
        })
        .catch((err) => {
          // Log fetch errors to the console
          console.log(err);
        });
    } else {
      setError("All fields are required...!!");
    }
  };

  return (
    <div id="signup">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div className="signup">
              <h3>Create Custom Security Questions</h3>
              <p>Create five Question & Answers</p>
              <div className="question-answer-container">
                {/* Question 1 and Answer 1 */}
                <div className="question-answer">
                  <TextField
                    label="Write your first question?"
                    variant="outlined"
                    margin="normal"
                    name="ques1"
                    fullWidth
                    value={userData.ques1}
                    onChange={(e) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        ques1: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="question-answer">
                  <TextField
                    label="Write your first answer?"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="ans1"
                    value={userData.ans1}
                    onChange={(e) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        ans1: e.target.value,
                      }))
                    }
                  />
                </div>

                {/* Question 2 and Answer 2 */}
                <div className="question-answer">
                  <TextField
                    label="Write your second question?"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="ques2"
                    value={userData.ques2}
                    onChange={(e) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        ques2: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="question-answer">
                  <TextField
                    label="Write your second answer?"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="ans2"
                    value={userData.ans2}
                    onChange={(e) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        ans2: e.target.value,
                      }))
                    }
                  />
                </div>

                {/* Question 3 and Answer 3 */}
                <div className="question-answer">
                  <TextField
                    label="Write your third question?"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="ques3"
                    value={userData.ques3}
                    onChange={(e) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        ques3: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="question-answer">
                  <TextField
                    label="Write your third answer?"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="ans3"
                    value={userData.ans3}
                    onChange={(e) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        ans3: e.target.value,
                      }))
                    }
                  />
                </div>

                {/* Question 4 and Answer 4 */}
                <div className="question-answer">
                  <TextField
                    label="Write your fourth question?"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="ques4"
                    value={userData.ques4}
                    onChange={(e) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        ques4: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="question-answer">
                  <TextField
                    label="Write your fourth answer?"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="ans4"
                    value={userData.ans4}
                    onChange={(e) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        ans4: e.target.value,
                      }))
                    }
                  />
                </div>

                {/* Question 5 and Answer 5 */}
                <div className="question-answer">
                  <TextField
                    label="Write your fifth question?"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="ques5"
                    value={userData.ques5}
                    onChange={(e) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        ques5: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="question-answer">
                  <TextField
                    label="Write your fifth answer?"
                    variant="outlined"
                    margin="normal"
                    fullWidth
                    name="ans5"
                    value={userData.ans5}
                    onChange={(e) =>
                      setUserData((prevUserData) => ({
                        ...prevUserData,
                        ans5: e.target.value,
                      }))
                    }
                  />
                </div>
              </div>

              {error && (
                <p className="error" style={{ color: "red" }}>
                  {error}
                </p>
              )}

              <div className="back_btn">
                <Button
                  id="sbbtn2"
                  variant="contained"
                  onClick={() => setCurrentStep(2)}
                >
                  Back
                </Button>
                <Button
                  id="sbbtn2"
                  variant="contained"
                  onClick={validateCustomQuestions}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Admincustomques;
