// src/admin/admincomponents/Adminlogin.js
import React, { useContext, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/Signup.css";
import "../adminstyle/adminsignin.css";
import { multiStepContextAdmin } from "../../StepContextAdmin";

const Adminlogin = () => {
  const { setCurrentStep, userData, setUserData } = useContext(
    multiStepContextAdmin
  );
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleNextClick1 = () => {
    if (userData.email === undefined || userData.email === "") {
      setError("Please enter an email.");
    } else {
      const email = userData.email;
      axios
        .post(
          "http://localhost:9000/api/admin/login",
          { email },
          {
            withCredentials: true,
          }
        )
        .then((response) => {
          if (response.status === 200) {
            toast.success("Admin Signin successful..!", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            setTimeout(() => {
              setCurrentStep(2);
            }, 5000);
          }
        })
        .catch((err) => {
          if (err.response) {
            const status = err.response.status;

            if (status === 401) {
              toast.error("Invalid email..! Please try again.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });
            } else {
              console.log(err);
            }
          }
        });
    }
  };

  return (
    <div id="adminsignup">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div className="signup">
              <h3>Admin Login</h3>
              <ToastContainer />

              <TextField
                margin="normal"
                fullWidth
                id="email"
                value={userData.email}
                onChange={(e) =>
                  setUserData((prevUserData) => ({
                    ...prevUserData,
                    email: e.target.value,
                  }))
                }
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />

              {error && (
                <p className="error" style={{ color: "red" }}>
                  {error}
                </p>
              )}
              <Button id="sbbtn" variant="contained" onClick={handleNextClick1}>
                Next
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Adminlogin;
