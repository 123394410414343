import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import Newcard from "./Newcard";
import "../styles/New.css";

const New = () => {
  const [cardData, setCardData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("http://localhost:9000/api/getnewdevelopments");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        // Ensure the data is correctly mapped
        const formattedData = data.map(item => ({
          imageUrl: `http://localhost:9000/${item.image}`,
          heading: item.Title,
          Address: item.Address,
        }));
        setCardData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Container className="new">
        <div className="newdevh">
          <h1>New Developments</h1>
        </div>

        <Grid container spacing={3}>
          {cardData.map((item, index) => (
            <Grid key={index} item xs={12} md={3}>
              <Newcard
          imageUrl={item.imageUrl}
          heading={item.heading}
          description={item.Address}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default New;
