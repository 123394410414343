import React from 'react';
import { IoIosBed } from "react-icons/io";
import { TbBathFilled } from "react-icons/tb";
import { FaCar } from "react-icons/fa6";
import '../styles/Homelistcard.css';
import { Link } from 'react-router-dom';

const Homelistcard = ({ id, imageUrl, heading, description, Bedrooms, Washrooms, Parkings }) => {
    return (
        <div>
            <div className="responsive_flx" id="intrntnl_dsply">
                <div className="res_sldr">
                    <div className="list-img">
                        {/* Wrap up the image with a Link */}
                        <Link to={`/image/${id}`} style={{ textDecoration: 'none' }}>
                            <img
                                src={imageUrl}
                                alt={heading}
                                style={{
                                    width: '100%', // Makes the image take full width of the container
                                    height: 'auto', // Maintains the aspect ratio of the image
                                    objectFit: 'cover', // Ensures the image covers the container without distortion
                                    imageRendering: 'auto', // Optimizes image rendering to maintain quality
                                    filter: 'none', // Ensures no filter is applied that could blur the image
                                }}
                            />
                        </Link>
                    </div>
                    <div className="tke_out">
                        <h6>{heading}</h6>
                        <p>{description}</p>
                        <div className="flx_svg">
                            <div className="hmest">
                                <IoIosBed size={19} />
                                <span>{Bedrooms}</span>
                            </div>
                            <div className="hmest">
                                <TbBathFilled size={19} />
                                <span>{Washrooms}</span>
                            </div>
                            <div className="hmest">
                                <FaCar size={18} />
                                <span>{Parkings}</span>
                            </div>
                            <div className="hmest">
                                <span><span className="dt_spn">.</span> House</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Homelistcard;
