import React, { useState } from "react";
import {
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Container,
  Grid,
} from "@mui/material";
import "../styles/Property.css";
import Propertycard from "./Propertycard";
import logo1 from "../images/lct.png";
import logo2 from "../images/sve.png";
import logo3 from "../images/lctt.png";

const Property = () => {
  const [selectedValue, setSelectedValue] = useState("Buying");

  const handleLabelClick = (value) => {
    setSelectedValue(value);
  };

  // Define content based on the selected value
  const renderContent = () => {
    switch (selectedValue) {
      case "Buying":
        return (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo1}
                heading="GET ESTIMATED PROPERTY PRICES WITH A REALESTIMATE"
                description="See how much your property's worth whether you own it or want to buy it"
                linkText="Check property values"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo2}
                heading="NEED HELP WITH A MORTGAGE?"
                description="Compare your finance options to make an informed call."
                linkText="Explore home loans"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo3}
                heading="EXPLORE SUBURB PROFILES"
                description="Check out different suburb profiles and find one that's right for you."
                linkText="Research suburbs"
              />
            </Grid>
          </Grid>
        );
      case "Renting":
        return (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo1}
                heading="FIND YOUR NEXT RENTAL PROPERTY"
                description="Browse through available rental listings."
                linkText="View rentals"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo2}
                heading="GET RENTAL ESTIMATES"
                description="Estimate rental prices in your area."
                linkText="Check rental prices"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo3}
                heading="EXPLORE RENTAL TIPS"
                description="Get advice on renting a property."
                linkText="Learn about renting"
              />
            </Grid>
          </Grid>
        );
      case "Selling":
        return (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo1}
                heading="GET A FREE PROPERTY APPRAISAL"
                description="Find out how much your property is worth."
                linkText="Request appraisal"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo2}
                heading="PREPARE YOUR HOME FOR SALE"
                description="Tips to make your home market-ready."
                linkText="Get tips"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo3}
                heading="UNDERSTAND THE SELLING PROCESS"
                description="Learn about selling your property."
                linkText="Read more"
              />
            </Grid>
          </Grid>
        );
      case "Researching":
        return (
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo1}
                heading="RESEARCH MARKET TRENDS"
                description="Stay informed about real estate trends."
                linkText="Explore trends"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo2}
                heading="FIND LOCAL PROPERTY AGENTS"
                description="Connect with agents in your area."
                linkText="Find agents"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Propertycard
                imageUrl={logo3}
                heading="ACCESS PROPERTY REPORTS"
                description="Get detailed reports on properties."
                linkText="View reports"
              />
            </Grid>
          </Grid>
        );
      default:
        return null;
    }
  };

  return (
    <div>
      <Container className="property">
        <h2>Property Knowledge</h2>
      </Container>
      <Container className="lidiv">
        <FormControl className="lifrom">
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
          >
            <FormControlLabel
              value="Buying"
              className={`Buying ${selectedValue === "Buying" ? "selected" : ""}`}
              control={<Radio style={{ display: "none" }} />}
              label="Buying"
              onClick={() => handleLabelClick("Buying")}
            />
            <FormControlLabel
              value="Renting"
              className={`Buying ${selectedValue === "Renting" ? "selected" : ""}`}
              control={<Radio style={{ display: "none" }} />}
              label="Renting"
              onClick={() => handleLabelClick("Renting")}
            />
            <FormControlLabel
              value="Selling"
              className={`Buying ${selectedValue === "Selling" ? "selected" : ""}`}
              control={<Radio style={{ display: "none" }} />}
              label="Selling"
              onClick={() => handleLabelClick("Selling")}
            />
            <FormControlLabel
              value="Researching"
              className={`Buying ${selectedValue === "Researching" ? "selected" : ""}`}
              control={<Radio style={{ display: "none" }} />}
              label="Researching"
              onClick={() => handleLabelClick("Researching")}
            />
          </RadioGroup>
        </FormControl>
      </Container>
      <Container>
        {renderContent()}
      </Container>
    </div>
  );
};

export default Property;