import React, { useState,useEffect } from 'react';
import Header from '../components/Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Footer from "../components/Footer";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  RadioGroup,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Radio
} from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import SettingsIcon from "@mui/icons-material/Settings";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FiShoppingCart } from "react-icons/fi";
import HomeIcon from '@mui/icons-material/Home'; 
import { MdSell } from "react-icons/md";
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { FiMessageCircle } from "react-icons/fi";
const Renting = ({ userId }) => {
  const [activeMenu, setActiveMenu] = useState("Renting");
  const [propertytype, setPropertyType] = useState("");
  const [Address, setAddress] = useState("");
  const [Price, setPrice] = useState("");
  const [Availability, setAvailability] = useState("");
  const [MoveInDate, setMoveInDate] = useState("");
  const [TypeOfproperty, setTypeOfProperty] = useState("");
  const [Bedrooms, setBedrooms] = useState("");
  const [Washrooms, setWashrooms] = useState("");
  const [SquareFootage, setSquareFootage] = useState("");
  const [Dimensions, setDimensions] = useState("");
  const [Parking, setParking] = useState("");
  const [Description, setDescription] = useState("");
  const [selectPropertyTypes, setSelectedPropertyTypes] = useState([]);
  const [LeasTerm, setLeaseTerm] = useState("");
  const [SecurityDeposit, setSecurityDeposit] = useState("");
  const [ApplicationFee, setApplicationFee] = useState("");
  const [UtilitiesIncluded, setUtilitiesIncluded] = useState("");
  const [ContactInformation, setContactInfo] = useState("");
  const [ScheduleViewing, setScheduleViewing] = useState("");
  const [RentalRequirements, setRentalRequirements] = useState("");
  const [parkingType, setParkingType] = useState("");
  const [PetsAllowed, setPetsAllowed] = useState("");
  const [applyNowLink, setApplyNowLink] = useState("");
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Parkings, setParkings] = useState("");
  const [images, setImages] = useState([]);
  const [videos, setVideos] = useState([]);

  const [imageFiles, setImageFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [videoFiles, setVideoFiles] = useState([]);
   const [videoPreviews, setVideoPreviews] = useState([]);
  const navigate = useNavigate();

  const handleMenuClick = (menu) => {
    if (activeMenu === menu) return; // Avoid unnecessary navigation

    setActiveMenu(menu);
    if (menu === "Buying") {
      navigate('/buying');
    } else if (menu === "Renting") {
      navigate('/rent');
    } else if (menu === "Selling") {
      navigate('/selling');
    }
    else if (menu === "Profile Settings") {
      navigate('/profile');
    }
    else if (menu === "Message") {
      navigate('/message');
    }
  };
  useEffect(() => {
    // Retrieve email from session storage
    const storedEmail = sessionStorage.getItem('userEmail'); // Adjust the key based on your implementation
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);
  const handlePropertyTypeChange = (event) => {
    const { value, checked } = event.target;
    setPropertyType((prev) =>
      checked ? [...prev, value] : prev.filter((item) => item !== value)
    );
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const sessionEmail = sessionStorage.getItem('userEmail');
    alert(sessionEmail);
  
    const formData = new FormData();

    // Append non-file data
    formData.append('Name', Name);
    formData.append('Email', Email);
    formData.append('propertytype', propertytype); // Ensure correct variable name
    formData.append('Address', Address);
    formData.append('Price', Price);
    formData.append('Availability', Availability);
    formData.append('TypeOfproperty', TypeOfproperty);
    formData.append('MoveInDate', MoveInDate);
    formData.append('Bedrooms', Bedrooms);
    formData.append('Washrooms', Washrooms);
    formData.append('Dimensions', Dimensions);
    formData.append('Parking', Parking);
    formData.append('Description', Description);
    formData.append('selectPropertyTypes', selectPropertyTypes); // Ensure this is included
    formData.append('SquareFootage', SquareFootage);
    formData.append('LeasTerm', LeasTerm);
    formData.append('SecurityDeposit', SecurityDeposit);
    formData.append('ApplicationFee', ApplicationFee);
    formData.append('UtilitiesIncluded', UtilitiesIncluded);
    formData.append('ContactInformation', ContactInformation);
    formData.append('ScheduleViewing', ScheduleViewing);
    formData.append('RentalRequirements', RentalRequirements);
    formData.append('parkingType', parkingType);
    formData.append('PetsAllowed', PetsAllowed);
    formData.append('applyNowLink', applyNowLink);
    formData.append('Parkings', Parkings);
  
 
    imageFiles.forEach(file => formData.append('images', file));
    videoFiles.forEach(file => formData.append('videos', file));
  
    try {
      const response = await fetch('http://localhost:9000/api/rentingproperty', {
        method: 'POST',
        body: formData, // Send formData as the body
      });
  
      console.log('Response Status:', response.status);
      console.log('Response Headers:', response.headers);
      const contentType = response.headers.get('Content-Type');
  
      if (contentType && contentType.includes('application/json')) {
        const data = await response.json();
        if (!response.ok) {
          throw new Error(`Network response was not ok: ${data.message}`);
        }
        toast.success('Form submitted successfully!');
        console.log(data);
      } else {
        const text = await response.text();
        throw new Error(`Unexpected response format: ${text}`);
      }
    } catch (error) {
      toast.error(`An error occurred while submitting the form: ${error.message}`);
      console.error(error);
    }
  };
     // Handle file input changes
     const handleFileChange = (event, type) => {
      const files = Array.from(event.target.files);
      if (files.length > 0) {
        if (type === 'image') {
          setImageFiles(prevFiles => [...prevFiles, ...files]);
          setImagePreviews(prevPreviews => [
            ...prevPreviews,
            ...files.map(file => URL.createObjectURL(file))
          ]);
        } else if (type === 'video') {
          setVideoFiles(prevFiles => [...prevFiles, ...files]);
          setVideoPreviews(prevPreviews => [
            ...prevPreviews,
            ...files.map(file => URL.createObjectURL(file))
          ]);
        }
      }
  
    };
    // Clean up URLs when component unmounts
    useEffect(() => {
      return () => {
        imagePreviews.forEach(preview => URL.revokeObjectURL(preview));
        videoPreviews.forEach(preview => URL.revokeObjectURL(preview));
      };
    }, [imagePreviews, videoPreviews]);
  return (
    <div>
       <Header />
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Box
          sx={{
            width: "321px",
            borderRight: "1px solid #ddd",
            padding: "20px",
          }}
        >
          <Typography variant="h6" gutterBottom>Menu</Typography>
          <List
            component="nav"
            sx={{
              padding: "0",
              backgroundColor: "#fff",
              boxShadow: "0 2px 4px 0 rgba(0,0,0,.1)",
            }}
          >
            <ListItem button
              className={`menu-item ${activeMenu === "Buying" ? "active" : ""}`}
              onClick={() => handleMenuClick("Buying")}
            >
              <ListItemIcon>
                <FiShoppingCart />
              </ListItemIcon>
              <ListItemText
                primary="Buying"
                primaryTypographyProps={{
                  sx: {
                    color: activeMenu === "Buying" ? "#000" : "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem button
              className={`menu-item ${activeMenu === "Renting" ? "active" : ""}`}
              onClick={() => handleMenuClick("Renting")}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Renting"
                primaryTypographyProps={{
                  sx: {
                    color: activeMenu === "Renting" ? "#000" : "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem button
              className={`menu-item ${activeMenu === "Selling" ? "active" : ""}`}
              onClick={() => handleMenuClick("Selling")}
            >
              <ListItemIcon>
                <MdSell />
              </ListItemIcon>
              <ListItemText
                primary="Selling"
                primaryTypographyProps={{
                  sx: {
                    color: activeMenu === "Selling" ? "#000" : "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem button
              className={`menu-item ${activeMenu === "Profile Settings" ? "active" : ""}`}
              onClick={() => handleMenuClick("Profile Settings")}
            >
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Profile Settings"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Message" ? "active" : ""}`}
  onClick={() => handleMenuClick("Message")}
>
              <ListItemIcon>
              <FiMessageCircle />
              </ListItemIcon>
              <ListItemText
                primary="Message"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: " 10px 0",
                  },
                }}
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ flex: 1, padding: "20px" }}>
          <Box component="form" onSubmit={handleSubmit} sx={{ marginTop: 2 }}>
            <Typography variant="h5" gutterBottom>
              Renting Properties
            </Typography>

            {/* Name Field */}
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  value={Name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>

              {/* Email Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  type="email"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>

            <Typography
              variant="h5"
              gutterBottom
              sx={{ color: "#787674", fontSize: { xs: "20px", md: "23px" }, marginTop: "40px" }}
            >
              Property Type
            </Typography>

            {/* Property Type Fields */}
            <Grid container spacing={2}>
              {["Apartment", "House", "Condo", "Townhouse", "Loft", "Studio"].map((type) => (
                <Grid item xs={12} sm={6} key={type}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={propertytype.includes(type)}
                        onChange={handlePropertyTypeChange}
                        value={type}
                      />
                    }
                    label={type}
                  />
                </Grid>
              ))}
            </Grid>

            <Grid container spacing={2}>
              {/* Address Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Address"
                  variant="outlined"
                  fullWidth
                  value={Address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Grid>

              {/* Price Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Price"
                  variant="outlined"
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  value={Price}
                  onChange={(e) => setPrice(e.target.value)}
                />
              </Grid>

              {/* Availability Field */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="availability-label">Availability</InputLabel>
                  <Select
                    labelId="availability-label"
                    value={Availability}
                    onChange={(e) => setAvailability(e.target.value)}
                    label="Availability"
                  >
                    <MenuItem value="Available Now">Available Now</MenuItem>
                    <MenuItem value="Move-in Date">Move-in Date</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Conditional Move-in Date Field */}
              {Availability === "Move-in Date" && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Move-in Date"
                    variant="outlined"
                    type="date"
                    fullWidth
                    InputLabelProps={{ shrink: true }}
                    value={MoveInDate}
                    onChange={(e) => setMoveInDate(e.target.value)}
                  />
                </Grid>
              )}

              {/* Bedrooms Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Bedrooms"
                  variant="outlined"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                  value={Bedrooms}
                  onChange={(e) => setBedrooms(e.target.value)}
                />
              </Grid>

              {/* Washrooms Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Washrooms"
                  variant="outlined"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0, max: 10 } }}
                  value={Washrooms}
                  onChange={(e) => setWashrooms(e.target.value)}
                />
              </Grid>

              {/* Square Footage Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Square Footage"
                  variant="outlined"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0 } }}
                  value={SquareFootage}
                  onChange={(e) => setSquareFootage(e.target.value)}
                />
              </Grid>

              {/* Property Type Field */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="property-type-label">Type of Property</InputLabel>
                  <Select
                    labelId="property-type-label"
                    value={TypeOfproperty}
                    onChange={(e) => setTypeOfProperty(e.target.value)}
                    label="Type of Property"
                  >
                    <MenuItem value="Furnished">Furnished</MenuItem>
                    <MenuItem value="Unfurnished">Unfurnished</MenuItem>
                    <MenuItem value="Semi-furnished">Semi-furnished</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Parking Type Field */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="parking-type-label">Parking</InputLabel>
                  <Select
                    labelId="parking-type-label"
                    value={Parking}
                    onChange={(e) => setParking(e.target.value)}
                    label="Parking"
                  >
                    <MenuItem value="Garage">Garage</MenuItem>
                    <MenuItem value="Street Parking">Street Parking</MenuItem>
                    <MenuItem value="Assigned Spots">Assigned Spots</MenuItem>
                  </Select>
                </FormControl>
              </Grid>

              {/* Pets Allowed Field */}
              <Grid item xs={12}>
                <FormControl component="fieldset">
                  <Typography variant="subtitle1">Pets Allowed</Typography>
                  <RadioGroup
                    value={PetsAllowed}
                    onChange={(e) => setPetsAllowed(e.target.value)}
                  >
                    <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                    <FormControlLabel value="No" control={<Radio />} label="No" />
                  </RadioGroup>
                </FormControl>
              </Grid>

              {/* Dimensions Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Dimensions"
                  variant="outlined"
                  fullWidth
                  value={Dimensions}
                  onChange={(e) => setDimensions(e.target.value)}
                />
              </Grid>

              {/* Parkings Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Parkings"
                  variant="outlined"
                  type="number"
                  fullWidth
                  InputProps={{ inputProps: { min: 0 } }}
                  value={Parkings}
                  onChange={(e) => setParkings(e.target.value)}
                />
              </Grid>

              {/* Description Field */}
              <Grid item xs={12}>
                <TextField
                  label="Description"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  value={Description}
                  onChange={(e) => setDescription(e.target.value)}
                />
              </Grid>

              {/* Lease Term Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Lease Term"
                  variant="outlined"
                  fullWidth
                  value={LeasTerm}
                  onChange={(e) => setLeaseTerm(e.target.value)}
                />
              </Grid>

              {/* Security Deposit Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Security Deposit"
                  variant="outlined"
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  value={SecurityDeposit}
                  onChange={(e) => setSecurityDeposit(e.target.value)}
                />
              </Grid>

              {/* Application Fee Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Application Fee"
                  variant="outlined"
                  fullWidth
                  type="number"
                  InputProps={{ inputProps: { min: 0 } }}
                  value={ApplicationFee}
                  onChange={(e) => setApplicationFee(e.target.value)}
                />
              </Grid>

              {/* Utilities Included Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Utilities Included"
                  variant="outlined"
                  fullWidth
                  value={UtilitiesIncluded}
                  onChange={(e) => setUtilitiesIncluded(e.target.value)}
                />
              </Grid>

              {/* Contact Information Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Contact Information"
                  variant="outlined"
                  fullWidth
                  value={ContactInformation}
                  onChange={(e) => setContactInfo(e.target.value)}
                />
              </Grid>

              {/* Schedule a Viewing Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Schedule a Viewing"
                  variant="outlined"
                  fullWidth
                  value={ScheduleViewing}
                  onChange={(e) => setScheduleViewing(e.target.value)}
                />
              </Grid>

              {/* Rental Requirements Field */}
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Rental Requirements"
                  variant="outlined"
                  fullWidth
                  multiline
                  rows={4}
                  value={RentalRequirements}
                  onChange={(e) => setRentalRequirements(e.target.value)}
                />
              </Grid>
             
               {/* <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Upload Images
                </Typography>
                <input
                  type="file"
                  multiple
                  accept="image/*"
                  onChange={handleImageChange}
                  style={{ display: 'block', marginBottom: '16px' }}
                />
              </Grid>

          
              <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                  Upload Videos
                </Typography>
                <input
                  type="file"
                  multiple
                  accept="video/*"
                  onChange={handleVideoChange}
                  style={{ display: 'block', marginBottom: '16px' }}
                />
              </Grid> */}
 <Grid item xs={12}>
<Typography
              variant="h5"
              gutterBottom
              sx={{ color: "#787674", fontSize: { xs: "20px", md: "23px" }, marginTop: "40px" }}
            >
              Upload Images & Videos
            </Typography>
            </Grid>
        
          {/* <div  style={{display:"flex",justifyContent:"space-around",alignContent:"center"}}> */}
          <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
     
                <Typography variant="h6" gutterBottom>Images</Typography>
                <label htmlFor="image-upload">
<Button
        variant="contained"
        // style={{border:"solid 5px red"}}
        component="label"
        startIcon={<CloudUploadIcon />}
        sx={{ marginTop: "10px", width: "fit-content" }}
      >
        Upload Images
        <input
          type="file"
          accept="image/*"
          multiple
          hidden
          onChange={(e) => handleFileChange(e, 'image')}
        />
      </Button>
      </label>
      
      {imagePreviews.length > 0 && (
        <Box sx={{ marginTop: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {imagePreviews.map((preview, index) => (
            <Box key={index} sx={{ width: '10%', maxHeight: '200px', position: 'relative' }}>
              <img
                src={preview}
                alt={`Selected Preview ${index}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          ))}
        </Box>
       
      )}
 </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>Videos</Typography>
        <input
  accept="video/*"
  id="video-upload"
  type="file"
  multiple
  style={{ display: 'none' }}
  onChange={(e) => handleFileChange(e, 'video')}
/>
        <label htmlFor="video-upload">
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
            
          >
            Upload Videos
          </Button>
        </label>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2 }}>
        {videoPreviews.map((preview, index) => (
  <Box key={index} sx={{ position: 'relative', width: { xs: '100px', md: '150px' }, height: { xs: '100px', md: '150px' } }}>
    <video
      src={preview}
      controls
      style={{ width: '100%', height: '100%' }}
    />
  </Box>
))}
        </Box>
        </Grid>
      </Grid>
            </Grid>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              fullWidth
              sx={{ marginTop: 4 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
      <ToastContainer />
    </div>
  );
};

export default Renting;
