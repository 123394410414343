import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Grid,
  IconButton,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";

const NewdevelopmentForm = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImagePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setSelectedFile(null);
      setImagePreview(null);
    }
  };

  const handleDeleteImage = () => {
    setSelectedFile(null);
    setImagePreview(null);
    document.getElementById("file-input").value = null;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("Title", title);
    formData.append("Address", address);
    formData.append("image", selectedFile);

    try {
      const response = await fetch("http://localhost:9000/api/newdevelopments/upload", {
        method: "POST",
        body: formData,
      });

      if (response.status === 200) {
        // Handle successful response
        console.log("Property added successfully");
      }
    } catch (error) {
      // Handle error response
      console.error("There was an error uploading the property!", error);
    }
  };

  return (
    <Box
      component="form"
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
        maxWidth: 600,
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
    >
      <Typography variant="h5" gutterBottom>
        Add New Development Homes
      </Typography>
      <TextField
        label="Title"
        variant="outlined"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      <TextField
        label="Address"
        variant="outlined"
        fullWidth
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <Button
            variant="contained"
            component="label"
            sx={{ width: "100%", height: "55px", backgroundColor: "#0f2481" }}
          >
            <CloudUploadIcon sx={{ marginRight: "10px" }} />
            Upload Image
            <input
              id="file-input"
              type="file"
              hidden
              onChange={handleFileChange}
              accept="image/*"
            />
          </Button>
        </Grid>
      </Grid>
      {imagePreview && (
        <Box sx={{ position: "relative", marginTop: 2 }}>
          <img src={imagePreview} alt="Preview" style={{ maxWidth: "100%" }} />
          <IconButton
            onClick={handleDeleteImage}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <DeleteIcon color="error" />
          </IconButton>
        </Box>
      )}
      <Button
        variant="contained"
        color="primary"
        sx={{
          width: "30%",
          margin: "0px auto",
          backgroundColor: "#0f2481",
          marginTop: "30px",
        }}
        type="submit"
      >
        Submit
      </Button>
    </Box>
  );
};

export default NewdevelopmentForm;
