import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import Footer from "../components/Footer";
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import SettingsIcon from "@mui/icons-material/Settings";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FiShoppingCart } from "react-icons/fi";
import HomeIcon from '@mui/icons-material/Home'; 
import { MdSell } from "react-icons/md";
import 'react-toastify/dist/ReactToastify.css';
import { FiMessageCircle } from "react-icons/fi";

const Selling = ({ userId }) => {
  const [activeMenu, setActiveMenu] = useState("Selling");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSubcategory, setSelectedSubcategory] = useState("");
  const [name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  // const [image, setImage] = useState("");
  const [city, setCity] = useState("");
  const [category, setCategory] = useState("");
  const [Subcategory, setSubcategory] = useState("");
  const [title, setTitle] = useState("");
  const [address, setAddress] = useState("");
  const [bedrooms, setBedrooms] = useState("");
  const[amount,setAmount]=useState("");
  const [washrooms, setWashrooms] = useState("");
  const [dimensions,setDimensions]=useState("");
  const [parkings, setParkings] = useState("");
  const [description,setDescription] = useState("");
  const [userData, setUserData] = React.useState({
    name: "",
    Email: "",
    mobileNumber: "",
    city: "",
    image: "",
    video: "",
    category: "",
    Subcategory: "",
    title: "",
    address: "",
    bedrooms: "",
    washrooms: "",
    parking: "",
    dimensions: "",
    amount: "",
   description: ""
 

    
  });

  const [cities, setCities] = useState(["New York", "Los Angeles", "Chicago", "Houston", "Phoenix",
    "Philadelphia",
"San Antonio",
"San Diego",
"Dallas",
"San Jose",
"London",
"Birmingham",
"Manchester",
"Glasgow",
"Leeds",
"Liverpool",
"Sheffield",
"Bristol",
"Edinburgh",
"Toronto",
"Vancouver",
"Montreal",
"Calgary",
"Ottawa",
"Edmonton",
"Winnipeg",
"Quebec City",
"Hamilton",
"Halifax",
"Sydney",
"Melbourne",
"Brisbane",
"Perth",
"Adelaide",
"Gold Coast",
"Canberra",
"Hobart",
"Darwin",
"Newcastle",
"Mumbai",
"Delhi",
"Bangalore",
"Hyderabad",
"Chennai",
"Kolkata",
"Pune",
"Ahmedabad",
"Jaipur",
"Surat",
"Beijing",
"Shanghai",
"Guangzhou",
"Shenzhen",
"Chengdu",
"Hong Kong",
"Hangzhou",
"Nanjing",
"Wuhan",
"Xi’an",
"Berlin",
"Munich",
"Frankfurt",
"Hamburg",
"Cologne",
"Stuttgart",
"Dusseldorf",
"Dortmund",
"Essen",
"Leipzig",
"Paris",
"Marseille",
"Lyon",
"Toulouse",
"Nice",
"Nantes",
"Montpellier",
"Strasbourg",
"Bordeaux",
"Lille",
  ]);
  // useEffect(() => {
  //   // Retrieve email from session storage
  //   const storedEmail = sessionStorage.getItem('userEmail'); // Adjust the key based on your implementation
  //   if (storedEmail) {
  //     setEmail(storedEmail);
  //   }
  // }, []);
  const [imageFiles, setImageFiles] = useState([]);
  const [imagePreviews, setImagePreviews] = useState([]);

  const [videoFiles, setVideoFiles] = useState([]);
   const [videoPreviews, setVideoPreviews] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch user data or other initialization logic here
  }, [userId]);

  const handleMenuClick = (menu) => {
    if (activeMenu === menu) return; // Avoid unnecessary navigation

    setActiveMenu(menu);
    if (menu === "Buying") {
      navigate('/buying');
    } else if (menu === "Renting") {
      navigate('/rent');
    } else if (menu === "Selling") {
      navigate('/selling');
    }
    else if (menu === "Profile Settings") {
      navigate('/profile');
    }
    else if (menu === "Message") {
      navigate('/message');
    }
  };

  const handleCategoryClick = (category) => {
    setCategory(category);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    const formData = new FormData();
    formData.append('name', name);
    formData.append('Email', Email);
    formData.append("Title", title);
    formData.append("Address", address);
    formData.append("Bedrooms", bedrooms);
    formData.append("Washrooms", washrooms);
    formData.append("Parkings", parkings);
    formData.append('mobileNumber', mobileNumber);
    formData.append('city', city);
    formData.append('category', selectedCategory);  // Ensure this is populated
    formData.append('Subcategory', selectedSubcategory);  // Ensure this is populated
    formData.append('title', title);
    formData.append('address', address);
    formData.append('bedrooms', bedrooms);  // Ensure this is populated
    formData.append('washrooms', washrooms);  // Ensure this is populated
    formData.append('parkings', parkings);  // Ensure this is populated
    formData.append('dimensions', dimensions);  // Ensure this is populated
    formData.append('amount', amount);  // Ensure this is populated
    formData.append('description', description);  // Ensure this is populated
   
    
  
    imageFiles.forEach(file => formData.append('images', file));
    videoFiles.forEach(file => formData.append('videos', file));
  
    try {
      const response = await fetch('http://localhost:9000/api/sellingproperty', {
        method: 'POST',
        body: formData,
      });
  
      if (response.ok) {
        const responseData = await response.json();
        alert('Form submitted successfully!');
        console.log('Response:', responseData);
      } else {
        alert('Failed to submit form');
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      alert('Error submitting form');
    }
  };
  const getHeading = () => {
    return activeMenu === "Buying" ? "Selling Properties" : "Selling";
  };

  const renderSubcategories = () => {
    switch (selectedCategory) {
      case 'Residential':
        return ['Rent', 'Resale', 'Farm House'];
      case 'Commercial':
        return ['Rent', 'Resale', 'Land/Plot'];
      case 'Land/Plot':
        return ['Resale'];
      default:
        return [];
    }
  };

  
   // Handle file input changes
   const handleFileChange = (event, type) => {
    const files = Array.from(event.target.files);
    if (files.length > 0) {
      if (type === 'image') {
        setImageFiles(prevFiles => [...prevFiles, ...files]);
        setImagePreviews(prevPreviews => [
          ...prevPreviews,
          ...files.map(file => URL.createObjectURL(file))
        ]);
      } else if (type === 'video') {
        setVideoFiles(prevFiles => [...prevFiles, ...files]);
        setVideoPreviews(prevPreviews => [
          ...prevPreviews,
          ...files.map(file => URL.createObjectURL(file))
        ]);
      }
    }

  };
  // Clean up URLs when component unmounts
  useEffect(() => {
    return () => {
      imagePreviews.forEach(preview => URL.revokeObjectURL(preview));
      videoPreviews.forEach(preview => URL.revokeObjectURL(preview));
    };
  }, [imagePreviews, videoPreviews]);



  const [selectedFile, setSelectedFile] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  



  return (
    <div>
      <Header />
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Box
          sx={{
            width: "321px",
            borderRight: "1px solid #ddd",
            padding: "20px",
          }}
        >
          <Typography variant="h6" gutterBottom>Menu</Typography>
          <List
            component="nav"
            sx={{
              padding: "0",
              backgroundColor: "#fff",
              boxShadow: "0 2px 4px 0 rgba(0,0,0,.1)",
            }}
          >
          <ListItem button
  className={`menu-item ${activeMenu === "Buying" ? "active" : ""}`}
  onClick={() => handleMenuClick("Buying")}
              >
              <ListItemIcon>
              <FiShoppingCart />
              </ListItemIcon>
              <ListItemText
                primary="Buying"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Renting" ? "active" : ""}`}
  onClick={() => handleMenuClick("Renting")}
>

              <ListItemIcon>
              <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Renting"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem button
  className={`menu-item ${activeMenu === "Selling" ? "active" : ""}`}
  onClick={() => handleMenuClick("Selling")}
>
              <ListItemIcon>
              <MdSell />
              </ListItemIcon>
              <ListItemText
                primary="Selling"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Profile Settings" ? "active" : ""}`}
  onClick={() => handleMenuClick("Profile Settings")}
>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Profile Settings"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Message" ? "active" : ""}`}
  onClick={() => handleMenuClick("Message")}
>
              <ListItemIcon>
              <FiMessageCircle />
              </ListItemIcon>
              <ListItemText
                primary="Message"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: " 10px 0",
                  },
                }}
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ flex: 1, padding: "20px" }}>
          <Box
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
              padding: 3,
              backgroundColor: 'white',
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
          >
            <Typography variant="h5" gutterBottom>
              {getHeading()}
            </Typography>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  sx={{ maxWidth: 400 }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  sx={{ maxWidth: 400 }}
                  type="Email"
                  value={Email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Mobile Number"
                  variant="outlined"
                  fullWidth
                  sx={{ maxWidth: 400 }}
                  type="MobileNumber"
                  value={mobileNumber}
                  onChange={(e) => setMobileNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ maxWidth: 400 }}>
                  <InputLabel id="city-label">City</InputLabel>
                  <Select
                    labelId="city-label"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    label="City"
                  >
                    {cities.map((city) => (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ color: "#787674", fontSize: { xs: "20px", md: "23px" }, marginTop: "40px" }}
            >
              Property Type
            </Typography>
            <Grid container spacing={2}>
  <Grid item xs={12} sm={6}>
    <FormControl fullWidth>
      <InputLabel id="category-label">Category</InputLabel>
      <Select
        labelId="category-label"
        value={selectedCategory}
        onChange={(e) => setSelectedCategory(e.target.value)}
        label="category"
      >
        <MenuItem value="Residential">Residential</MenuItem>
        <MenuItem value="Commercial">Commercial</MenuItem>
        <MenuItem value="Land/Plot">Land/Plot</MenuItem>
      </Select>
    </FormControl>
  </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id="subcategory-label">Subcategory</InputLabel>
                  <Select
                    labelId="subcategory-label"
                    value={selectedSubcategory}
                    onChange={(e) => setSelectedSubcategory(e.target.value)}
                    label="Subcategory"
                  >
                    {renderSubcategories().map((sub, index) => (
                      <MenuItem key={index} value={sub}>
                        {sub}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
            <Grid item xs={6}>  
 <TextField
        label="Title"
        variant="outlined"
        fullWidth
        value={title}
        onChange={(e) => setTitle(e.target.value)}
      />
      </Grid>
       <Grid item xs={6}>
      <TextField
        label="Address"
        variant="outlined"
        fullWidth
        value={address}
        onChange={(e) => setAddress(e.target.value)}
      />
</Grid>

</Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Bedrooms"
            variant="outlined"
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 0, max: 10 } }} // Adding min and max values
            value={bedrooms}
            onChange={(e) => setBedrooms(e.target.value)}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            label="Washrooms"
            variant="outlined"
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 0, max: 10 } }} // Adding min and max values
            value={washrooms}
            onChange={(e) => setWashrooms(e.target.value)}
          />
        </Grid>

      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            label="Parkings"
            variant="outlined"
            type="number"
            fullWidth
            InputProps={{ inputProps: { min: 0, max: 10 } }} // Adding min and max values
            value={parkings}
            onChange={(e) => setParkings(e.target.value)}
          />
          </Grid>
  <Grid item xs={6}>
    <TextField
      label="Dimensions"
      variant="outlined"
      fullWidth
      InputProps={{ inputProps: { min: 0, max: 10 } }} // Adding min and max values
      value={dimensions}
      onChange={(e) => setDimensions(e.target.value)}
    />
  </Grid>
  <Grid item xs={6}>
    <TextField
      label="Amount"
      variant="outlined"
      fullWidth
      InputProps={{ inputProps: { min: 0, max: 10 } }} // Adding min and max values
      value={amount}
      onChange={(e) => setAmount(e.target.value)}
    />
  </Grid>
  <Grid item xs={6}>
    <TextField
      label="Description"
      variant="outlined"
      fullWidth
      InputProps={{ inputProps: { min: 0, max: 10 } }} // Adding min and max values
      value={description}
      onChange={(e) => setDescription(e.target.value)}
    />
  </Grid>
</Grid>

        <Typography
              variant="h5"
              gutterBottom
              sx={{ color: "#787674", fontSize: { xs: "20px", md: "23px" }, marginTop: "40px" }}
            >
              Upload Images & Videos
            </Typography>
            
        
          {/* <div  style={{display:"flex",justifyContent:"space-around",alignContent:"center"}}> */}
          <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
     
                <Typography variant="h6" gutterBottom>Images</Typography>
                <label htmlFor="image-upload">
<Button
        variant="contained"
        // style={{border:"solid 5px red"}}
        component="label"
        startIcon={<CloudUploadIcon />}
        sx={{ marginTop: "10px", width: "fit-content" }}
      >
        Upload Images
        <input
          type="file"
          accept="image/*"
          multiple
          hidden
          onChange={(e) => handleFileChange(e, 'image')}
        />
      </Button>
      </label>
      
      {imagePreviews.length > 0 && (
        <Box sx={{ marginTop: 2, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          {imagePreviews.map((preview, index) => (
            <Box key={index} sx={{ width: '10%', maxHeight: '200px', position: 'relative' }}>
              <img
                src={preview}
                alt={`Selected Preview ${index}`}
                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
              />
            </Box>
          ))}
        </Box>
       
      )}
 </Grid>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>Videos</Typography>
        <input
  accept="video/*"
  id="video-upload"
  type="file"
  multiple
  style={{ display: 'none' }}
  onChange={(e) => handleFileChange(e, 'video')}
/>
        <label htmlFor="video-upload">
          <Button
            variant="contained"
            color="primary"
            component="span"
            startIcon={<CloudUploadIcon />}
            
          >
            Upload Videos
          </Button>
        </label>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, marginTop: 2 }}>
        {videoPreviews.map((preview, index) => (
  <Box key={index} sx={{ position: 'relative', width: { xs: '100px', md: '150px' }, height: { xs: '100px', md: '150px' } }}>
    <video
      src={preview}
      controls
      style={{ width: '100%', height: '100%' }}
    />
  </Box>
))}
        </Box>
        </Grid>
      </Grid>
           {/* </div> */}
            <Button
              variant="contained"
              color="primary"
              type="submit"
              sx={{ marginTop: 4 }}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Selling;