import * as React from "react";
import { useState } from "react";
import { useNavigate,useLocation } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import "../styles/Signin.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";
import logo from "../images/logo.png";

const Changepassword = () => {
  const [password, setPassword] = useState("");
  const location = useLocation();
  const { email } = location.state || {};
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      const response = await fetch("http://localhost:9000/api/changepassword", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({email, password }),
      });

      if (response.ok) {
        toast.success("Password changed successfully");
        navigate("/profile"); // Redirect to the desired page
      } else {
        toast.error("Failed to change password");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred");
    }
  };

  return (
    <div id="signin">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div className="signup">
              <h3>Change Your Password</h3>

              <ToastContainer />
              <form onSubmit={handleSubmit}>
                <TextField
                  margin="normal"
                  fullWidth
                  id="password"
                  type="password"
                  label="Password"
                  name="password"
                  autoComplete="new-password"
                  autoFocus
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <TextField
                  margin="normal"
                  fullWidth
                  name="confirmPassword"
                  label="Re-Enter Password"
                  type="password"
                  id="confirmPassword"
                  autoComplete="new-password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <Button id="sbbtn" type="submit" fullWidth variant="contained">
                  Change Password
                </Button>
              </form>
            </div>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Changepassword;
