import React, { useEffect, useState } from "react";
import { Container, Grid } from "@mui/material";
import Dreamcard from "./Dreamcard";
import "../styles/Dreamhome.css";

const Dreamhome = () => {
  const [dreamHomes, setDreamHomes] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("http://localhost:9000/api/getdreamhomes");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        // Ensure the data is correctly mapped
        const formattedData = data.map(item => ({
          imageUrl: `http://localhost:9000/${item.image}`,
          heading: item.Title,
          Address: item.Address,
          Bedrooms: item.Bedrooms,
          Washrooms: item.Washrooms,
          Parkings: item.Parkings
        }));
        setDreamHomes(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <Container className="dreamhome">
        <h1>Dream Homes</h1>
      </Container>

      <Container>
        <Grid container spacing={3}>
          {dreamHomes.map((home, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Dreamcard
                 imageUrl={home.imageUrl}
                 heading={home.heading}
                 description={home.Address}
                 Bedrooms={home.Bedrooms}
                 Washrooms={home.Washrooms}
                 Parkings={home.Parkings}
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </div>
  );
};

export default Dreamhome;
