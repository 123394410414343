import React, { useState, useEffect } from "react";
import { Container, Grid } from "@mui/material";
import Latestcard from "./Latestcard";
import "../styles/Latest.css";

const Latest = () => {
  // State to hold the news data
  const [newsData, setNewsData] = useState([]);

  // Fetch data from the API when the component mounts
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("http://localhost:9000/api/getupload");
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        // Map the data to the structure needed for Latestcard
        const formattedData = data.map(item => ({
          imageUrl: `http://localhost:9000/${item.image}`, // Assuming the image path is stored in the `image` field
          heading: item.title, // Assuming the title is stored in the `title` field
          timeline: item.timeline // Replace with actual data if available
        }));
        setNewsData(formattedData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array means this useEffect runs once on mount

  return (
    <Container sx={{ textAlign: "center", py: 4 }} className="latest">
      <h2>Latest property news</h2>

      <Grid container spacing={4}>
        {newsData.map((item, index) => (
          <Grid key={index} item xs={12} md={4}>
            <Latestcard
              imageUrl={item.imageUrl}
              heading={item.heading}
              timeline={item.timeline} // Replace with actual timeline if available
            />
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Latest;
