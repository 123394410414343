import React, { useState, useEffect } from 'react';
import Header from "../components/Header";
import Footer from "../components/Footer";
import { FiShoppingCart } from "react-icons/fi";
import HomeIcon from '@mui/icons-material/Home'; 
import { MdSell } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import "../styles/Profile.css";
import { FiMessageCircle } from "react-icons/fi";
import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
  TextField,
  Button,
  Divider,
  Grid,
  Modal,
  Fade,
  Backdrop,
  IconButton,
} from "@mui/material";
import ViewListIcon from "@mui/icons-material/ViewList";
import MessageIcon from "@mui/icons-material/Message";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import CloseIcon from "@mui/icons-material/Close";
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Profile = ({ userId }) => {
  const [open, setOpen] = React.useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [activeMenu,setActiveMenu]= useState("null")
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFile, setSelectedFile] = React.useState(null);
  const [firstname, setFirstName] = useState("");
  const [phonenumber, setPhoneNumber] = useState("");
  const [location, setLocation] = useState("");
  const [preview, setPreview] = React.useState("");
  const [userData, setUserData] = React.useState({
    firstname: "",
    email: "",
    phonenumber: "",
    location: "",
  });

  const navigate = useNavigate(); // Hook for navigation

  const handleMenuClick = (menu) => {
    if (activeMenu === menu) return; // Avoid unnecessary navigation

    setActiveMenu(menu);
    if (menu === "Buying") {
      navigate('/buying');
    } else if (menu === "Renting") {
      navigate('/rent');
    } else if (menu === "Selling") {
      navigate('/selling');
    }
    else if (menu === "Message") {
      navigate('/message');
    }
  };
  const [passwordData, setPasswordData] = React.useState({
    email: "",
    newPassword: "",
    confirmNewPassword: ""
  });

  const fileInputRef = React.useRef(null);

React.useEffect(() => {
  const fetchUserData = async () => {
    try {
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch(`http://localhost:9000/api/getuserbyid/${userId}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      });

      if (response.ok) {
        const data = await response.json();
        setUserData({
          firstname: data.firstname || "",
          email: data.email || "",
          phoneNumber: data.phoneNumber || "",
          location: data.location || "",
        });
      } else {
        console.error("Failed to fetch user data");
      }
    } catch (error) {
      console.error("An error occurred while fetching user data:", error);
    }
  };

  if (userId) {
    fetchUserData();
  }
}, [userId]);
useEffect(() => {
  const fetchImage = async () => {
    try {
      const userId = sessionStorage.getItem("userId");
      if (!userId) return;

      const response = await fetch(`http://localhost:9000/api/getimage/upload/${userId}`);
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();

      if (data.image) {
        setImagePreview(`http://localhost:9000/${data.image}`);
      } else {
        console.error("Image URL not found in response data");
      }

      if (data.firstname) {
        setFirstName(data.firstname);
        setUserData(prev => ({ ...prev, firstname: data.firstname })); // Sync with userData state
      } else {
        console.error("Phone number not found in response data");
      }


      if (data.phonenumber) {
        setPhoneNumber(data.phonenumber);
        setUserData(prev => ({ ...prev, phonenumber: data.phonenumber })); // Sync with userData state
      } else {
        console.error("Phone number not found in response data");
      }

      if (data.location) {
        setLocation(data.location);
        setUserData(prev => ({ ...prev, location: data.location })); // Sync with userData state
      } else {
        console.error("Location not found in response data");
      }

    } catch (error) {
      console.error("Error fetching image:", error);
      toast.error("Failed to load image");
    }
  };

  fetchImage();
}, []);

// Handle file change and upload


const handleImageChange = async (event) => {
  const sessionEmail = sessionStorage.getItem('userEmail');

  if (!sessionEmail) {
    console.error("No email found in session storage");
    return;
  }

  const formData = new FormData();
  if (fileInputRef.current && fileInputRef.current.files[0]) {
    formData.append('image', fileInputRef.current.files[0]);
    formData.append('email', sessionEmail);

    try {
      const response = await fetch("http://localhost:9000/api/profileimage/upload", {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      const imagePreview = result.url; // Adjust based on the actual response property

      if (imagePreview) {
        // Update the state with the image URL
        const fullImageUrl = `http://localhost:9000/${imagePreview}`;
        setImagePreview(fullImageUrl);
        
        // Show an alert with the image URL
        alert(`Image uploaded successfully. Preview URL: ${fullImageUrl}`);
        
        // Redirect to a different page (e.g., profile page) after successful upload
     window.location.reload();
      } else {
        console.error('Image URL not found in response data');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.error("Failed to upload image");
    }
  }
};
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setSelectedFile(null);
    setPreview("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };


  

  const handleSubmit = async (e) => {
    e.preventDefault();
    const sessionEmail = sessionStorage.getItem('userEmail');
  
    if (!sessionEmail) {
      console.error("No email found in session storage");
      return;
    }
  
    const formData = new FormData();
    if (fileInputRef.current && fileInputRef.current.files[0]) {
      formData.append('image', fileInputRef.current.files[0]);
    }
    
    // Append phone number and location to form data
    formData.append('email', sessionEmail);
    formData.append('firstname', userData.firstname);
    formData.append('phonenumber', userData.phonenumber);
    formData.append('location', userData.location);
    
  
    try {
      const response = await fetch("http://localhost:9000/api/profileimage/upload", {
        method: "POST",
        body: formData,
      });
  
      if (response.ok) {
        const data = await response.json();
        setUserData({
          firstname: data.firstname || "",
          phonenumber: data.phonenumber || "",
          location: data.location || "",
        });
        toast.success("Profile updated successfully!");
        console.log("Profile updated successfully:", data);
      } else {
        const errorText = await response.text();
        console.error("Failed to update profile:", errorText);
        toast.error("Failed to update profile");
      }
    } catch (error) {
      console.error("There was an error updating the profile!", error);
      toast.error("There was an error updating the profile!");
    }
  };
 const handleSave = async () => {
  try {
    // Call handleSubmit logic to ensure form data is processed
    await handleSubmit(new Event('submit')); // Simulate a submit event

    // You can add additional save logic here if needed
    console.log("Save operation completed.");
    handleClose(); // Close the form or modal after saving
  } catch (error) {
    console.error("Error in handleSave:", error);
  }
};

  const handlePasswordChange = (event) => {
    const { name, value } = event.target;
    setPasswordData({ ...passwordData, [name]: value });
  };

  const handlePasswordSave = async () => {
    if (passwordData.newPassword !== passwordData.confirmNewPassword) {
      toast.error("Passwords do not match");
      return;
    }

    try {
      const sessionEmail = sessionStorage.getItem('userEmail');
  
      if (sessionEmail !== passwordData.email) {
        toast.error("Email does not match the logged-in user's email");
        return;
      }
  
      const accessToken = localStorage.getItem("accessToken");
      const response = await fetch("http://localhost:9000/api/changepassworduser", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          email: passwordData.email,
          newPassword: passwordData.newPassword,
          confirmNewPassword: passwordData.confirmNewPassword
        }),
      });
  
      if (response.status === 200) {
        toast.success("Password changed successfully!");
        setPasswordData({
          email: "",
          newPassword: "",
          confirmNewPassword: "",
        });
      } else if (response.status === 400) {
        const result = await response.json();
        console.error("Failed to change password:", result.message);
        toast.error(result.message || "Failed to change password");
      } else {
        console.error("An unexpected error occurred");
        toast.error("An unexpected error occurred");
      }
    } catch (error) {
      console.error("An error occurred while changing password:", error);
      toast.error("An error occurred while changing password");
    }
  };



  return (
    <div>
      <Header />
      <Box sx={{ display: "flex", minHeight: "100vh" }}>
        <Box
          sx={{
            width: "321px",
            borderRight: "1px solid #ddd",
            padding: "20px",
          }}
        >
          <Typography variant="h6" gutterBottom>Menu</Typography>
          <List
            component="nav"
            sx={{
              padding: "0",
              backgroundColor: "#fff",
              boxShadow: "0 2px 4px 0 rgba(0,0,0,.1)",
            }}
          >
           <ListItem button
  className={`menu-item ${activeMenu === "Buying" ? "active" : ""}`}
  onClick={() => handleMenuClick("Buying")}
              >
              <ListItemIcon>
              <FiShoppingCart />
              </ListItemIcon>
              <ListItemText
                primary="Buying"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: " 10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Renting" ? "active" : ""}`}
  onClick={() => handleMenuClick("Renting")}
>
              <ListItemIcon>
              <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Renting"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: " 10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
        
            <ListItem button
  className={`menu-item ${activeMenu === "Selling" ? "active" : ""}`}
  onClick={() => handleMenuClick("Selling")}
>
              <ListItemIcon>
              <MdSell />
              </ListItemIcon>
              <ListItemText
                primary="Selling"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: " 10px 0",
                  },
                }}
              />
            </ListItem>
           
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Profile Settings" ? "active" : ""}`}
  onClick={() => handleMenuClick("Profile Settings")}
>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Profile Settings"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: " 10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Message" ? "active" : ""}`}
  onClick={() => handleMenuClick("Message")}
>
              <ListItemIcon>
              <FiMessageCircle />
              </ListItemIcon>
              <ListItemText
                primary="Message"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: " 10px 0",
                  },
                }}
              />
            </ListItem>
          </List>
        </Box>
        <Box sx={{ flex: 1, padding: "20px" }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ color: "#787674", fontSize: "23px" }}
          >
            Contact Information
          </Typography>
          <div className="profile_body">
            <Box
              component="form"
              sx={{ display: "flex", flexDirection: "column", gap: 2 }}
            >
              <Grid container spacing={2}>
                <Grid item xs={4} sx={{ paddingLeft: "0!important" }}>
                  <h3 className="prfl-pic">Profile Photo</h3>
                  <div className="profile_upload">
      <label htmlFor="profileupload">
      <div
          style={{
           height:"100%",
            borderRadius: '50%',
            overflow: 'hidden',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: '#f0f0f0',
          }}
        >
          {imagePreview ? (
            <img
            src={imagePreview}
              alt="Profile Preview"
              style={{ width: '100%', height: '100%', objectFit: 'cover' }}
            />
          ) : (
            <span>
              <PersonAddIcon />
              <p>Add Photo</p>
            </span>
          )}
        </div>
      </label>
      
      <TextField
        type="file"
        id="profileupload"
        sx={{ display: "none" }}
        onChange={handleImageChange}
        inputRef={fileInputRef}
        onClick={(e) => e.stopPropagation()}
      />

    </div>
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    label="Name"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: "16px", width: "50%" }}
                    value={userData.firstname || sessionStorage.getItem('userFirstname') || ""} // Use userData.firstname with a fallback to sessionStorage
                    onChange={(e) =>
                      setUserData({ ...userData, firstname: e.target.value })
                    }
                  />
                  <br />
                  <TextField
                    label="Email"
                        variant="filled"
                    InputProps={{
                      readOnly: true,
                    }}
                fullWidth
                    sx={{ marginBottom: "16px", width: "50%" }}
                    value={sessionStorage.getItem('userEmail')}
                    onChange={(e) =>
                      setUserData({ ...userData, email: e.target.value })
                    }
                  />
                  <br />
                  <TextField
                    label="Phone Number"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: "16px", width: "50%" }}
                    value={userData.phonenumber}
                    onChange={(e) =>
                      setUserData({ ...userData, phonenumber: e.target.value })
                    }
                  />
                  <br />
                  <TextField
                    label="Location"
                    variant="outlined"
                    fullWidth
                    sx={{ marginBottom: "16px", width: "70%" }}
                    value={userData.location}
                    onChange={(e) =>
                      setUserData({ ...userData, location: e.target.value })
                    }
                  />
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ padding: "15px 40px", fontWeight: "bold" }}
                  
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </div>
          <div>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ color: "#787674", fontSize: "23px", marginTop: "40px" }}
            >
              Change Password
            </Typography>
            <div className="profile_body">
              <Box
                component="form"
                sx={{ display: "flex", flexDirection: "column", gap: 2 }}
              >
                <Grid container spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      label="Email"
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: "16px", width: "50%" }}
                      name="email"
                      value={passwordData.email}
                      onChange={handlePasswordChange}
                    />
                    <br />
                    <TextField
                      label="New Password"
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: "16px", width: "50%" }}
                      type="password"
                      name="newPassword"
                      value={passwordData.newPassword}
                      onChange={handlePasswordChange}
                    />
                    <br />
                    <TextField
                      label="Confirm New Password"
                      variant="outlined"
                      fullWidth
                      sx={{ marginBottom: "16px", width: "50%" }}
                      type="password"
                      name="confirmNewPassword"
                      value={passwordData.confirmNewPassword}
                      onChange={handlePasswordChange}
                    />
                    <br />
                    <Button
                      variant="contained"
                      color="primary"
                      sx={{ padding: "15px 40px", fontWeight: "bold" }}
                      onClick={handlePasswordSave}
                    >
                      Save Password
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </div>
          </div>
        </Box>
      </Box>
      <Footer />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <Box
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: 400,
              bgcolor: "background.paper",
              border: "2px solid #000",
              boxShadow: 24,
              p: 4,
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography
              id="transition-modal-title"
              variant="h6"
              component="h2"
            >
              Preview
            </Typography>
            <Box
              component="img"
              sx={{
                mt: 2,
                width: "100%",
                maxHeight: 400,
                objectFit: "contain",
              }}
              src={preview}
              alt="Profile Preview"
            />
            <Box
              sx={{
                mt: 2,
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button onClick={handleClose} variant="contained">
                Cancel
              </Button>
              <Button onClick={handleSave} variant="contained" color="primary">
                Save
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default Profile;