import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Typography, IconButton, Grid, Button } from "@mui/material";
import Carousel from 'react-material-ui-carousel';
import { MdChevronRight, MdChevronLeft, MdClose } from "react-icons/md";
import Header from '../components/Header';
import { IoIosBed } from "react-icons/io";
import { TbBathFilled } from "react-icons/tb";
import { FaCar } from "react-icons/fa";
import Footer from "../components/Footer";
import '../styles/Imagepage.css';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';

const ImagePage = () => {
    const { id } = useParams();
    const [images, setImages] = useState([]);
    const [videos, setVideos] = useState([]);
    const [openModal, setOpenModal] = useState(false);
    const [contactMessage, setContactMessage] = useState('');
    const [selectedMedia, setSelectedMedia] = useState(null);
    const [carouselOpen, setCarouselOpen] = useState(false);
    const [currentIndex, setCurrentIndex] = useState(0);

    const MAX_THUMBNAILS = 4;
    const allMedia = [...images, ...videos];
    const displayMedia = allMedia.slice(0, MAX_THUMBNAILS);
    const additionalCount = allMedia.length - MAX_THUMBNAILS;
    const additionalMediaPreview = additionalCount > 0 ? allMedia[MAX_THUMBNAILS] : null;

    const [userData, setUserData] = useState({
        Address: "",
        name: "",
        Email: "",
        mobileNumber: "",
        city: "",
        title: "",
        Bedrooms: "",
        Washrooms: "",
        Parkings: "",
        category: "",
        Subcategory: "",
        dimensions: "",
        amount: "",
        description: "",
        videos: []
    });

    const videoRef = useRef(null);

    useEffect(() => {
        const fetchUserData = async () => {
            try {
                const response = await fetch(`http://localhost:9000/api/getbyseller/${id}`);
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                const data = await response.json();
                if (data) {
                    setUserData(data);
                    
                    if (data.images && Array.isArray(data.images)) {
                        setImages(data.images.map(image => `http://localhost:9000/${image}`));
                    }
                    if (data.videos && Array.isArray(data.videos)) {
                        setVideos(data.videos.map(video => `http://localhost:9000/${video}`));
                    }
                    const initialMedia = data.images.length > 0 ? `http://localhost:9000/${data.images[0]}` : (data.videos.length > 0 ? `http://localhost:9000/${data.videos[0]}` : null);
                    setSelectedMedia(initialMedia);
                    setCurrentIndex(data.images.length > 0 ? 0 : data.images.length);
                }
            } catch (error) {
                console.error('Error fetching user data:', error);
            }
        };

        fetchUserData();
    }, [id]);

    useEffect(() => {
        if (carouselOpen && selectedMedia?.endsWith('.mp4') && videoRef.current) {
            videoRef.current.play();
        }
    }, [carouselOpen, selectedMedia]);

    const handleMediaChange = (index) => {
        setSelectedMedia(allMedia[index]);
        setCurrentIndex(index);
    };

    const handleCarouselOpen = () => {
        setCarouselOpen(true);
    };

    const handleCloseCarousel = () => {
        setCarouselOpen(false);
    };

    const handleContactClick = () => {
        setOpenModal(true);
    };

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleSendMessage = async () => {
        try {
          // Prepare the data to be sent
          const email = sessionStorage.getItem('userEmail') || 'No Email Provided';
          const firstname = sessionStorage.getItem('userFirstname') || 'No Email Provided';
          const requestData = {
            Message: contactMessage,  // Ensure this is a string
            email: email,
            firstname: firstname,
                      // Ensure 'id' corresponds to the property (e.g., userId)
          };
      
          // Alert to debug (optional)
          alert(`Sending data:\n${JSON.stringify(requestData, null, 2)}`);
          
          // Make the POST request to the backend API
          const response = await fetch(`http://localhost:9000/api/buyingproperty/${id}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',  // Sending JSON data
            },
            body: JSON.stringify(requestData), // Send requestData as a JSON string
          });
      
          // Check if response is OK (status code 2xx)
          if (!response.ok) {
            const errorData = await response.json(); // Parse error response if not ok
            throw new Error(errorData.message || 'Something went wrong');
          }
      
          // Parse the response body
          const result = await response.json();
          console.log(result);  // Log result for debugging
      
          // Optionally reset the form or show a success message
          setOpenModal(false);
          setContactMessage('');
          
        } catch (error) {
          console.error('Error sending message:', error);
        }
      };
    

    return (
        <div>
            <Header />
            <Grid className='main_1' container spacing={3} style={{ marginTop: "0px" }}>
                <Grid item xs className="desc">
                    <Box className="hello" sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, marginLeft: "10px" }}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <Typography variant="body1">{userData.category}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ textAlign: "right" }} variant="body1"><span className="resale">{userData.Subcategory}</span></Typography>
                            </Grid>
                        </Grid>
                        <Typography variant="h5" sx={{ fontWeight: "600", textTransform: "uppercase", marginTop: "14px" }} gutterBottom>
                            {userData.Address}
                        </Typography>
                        <Typography variant="body1" sx={{ marginBottom: "16px", fontSize: "17px" }} color="textSecondary">
                            {userData.title}
                        </Typography>
                        <div className="flx_svg">
                            <div className="hmest"><IoIosBed size={19} /><span>{userData.Bedrooms}</span></div>
                            <div className="hmest"><TbBathFilled size={19} /><span>{userData.Washrooms}</span></div>
                            <div className="hmest"><FaCar size={18} /><span>{userData.Parkings}</span></div>
                            <div className="hmest"><span><span className="dt_spn"></span> </span></div>
                        </div>
                        <div style={{ marginTop: "13px" }}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="body2"><p className='amount'> <b>Amount: </b> <span>{userData.amount}</span></p></Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography sx={{  }} variant="body2">
                                        <p className='dimension'><b>Dimension: </b><span>{userData.dimensions}</span></p>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </div>
                    </Box>
                    <Grid item xs>
                        <Box className="hello7" sx={{ p: 2, bgcolor: 'background.paper', borderRadius: 1, marginLeft: '10px', marginTop: '21px' }}>
                            <Typography variant="h6" gutterBottom style={{ fontWeight: 'bold', textTransform: "uppercase", marginBottom: '10px' }}>
                                {userData.name || "No Name Provided"}
                            </Typography>
                            <Typography variant="body1" color="textSecondary" style={{ fontSize: "17px", marginBottom: '8px' }}>
                                Email: <span>{userData.Email || 'No Email Provided'}</span>
                            </Typography>
                            <Typography variant="body1" color="textSecondary" style={{ fontSize: "17px", marginBottom: '8px' }}>
                                Mobile: <span>{userData.mobileNumber || 'No Phone Number Provided'}</span>
                            </Typography>
                            <Typography variant="body1" color="textSecondary" style={{ fontSize: "17px" }}>
                                City: <span>{userData.city || 'No Location Provided'}</span>
                            </Typography>
                            <Box sx={{ mt: 2 }}>
                            <Button
                                variant="contained"
                                color="primary"
                                sx={{
                                    textTransform: 'none',
                                    bgcolor: '#007BFF',
                                    '&:hover': {
                                        bgcolor: '#0056b3',
                                    },
                                    padding: '10px 20px',
                                    borderRadius: '4px',
                                    fontWeight: 'bold',
                                    width:"100%"
                                }}
                                onClick={handleContactClick}
                            >
                                Contact
                            </Button>
                        </Box>
                        </Box>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Box
                        sx={{
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            cursor: 'pointer',
                            position: 'relative',
                            width: '100%',
                            height: '380px',
                            overflow: 'hidden',
                        }}
                        onClick={handleCarouselOpen}
                    >
                        {selectedMedia && (selectedMedia.endsWith('.mp4') ? (
                            <video
                                ref={videoRef}
                                src={selectedMedia}
                                alt="Selected"
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                                controls
                                autoPlay
                                muted
                            />
                        ) : (
                            <img
                                src={selectedMedia}
                                alt="Selected"
                                style={{ width: '100%', height: '100%', objectFit: 'contain' }}
                            />
                        ))}

                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                left: '10px',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                },
                                transform: 'translateY(-50%)',
                                zIndex: 10,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                const prevIndex = (currentIndex - 1 + allMedia.length) % allMedia.length;
                                handleMediaChange(prevIndex);
                            }}
                        >
                            <MdChevronLeft size={30} />
                        </IconButton>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: '50%',
                                right: '10px',
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                },
                                transform: 'translateY(-50%)',
                                zIndex: 10,
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                const nextIndex = (currentIndex + 1) % allMedia.length;
                                handleMediaChange(nextIndex);
                            }}
                        >
                            <MdChevronRight size={30} />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs style={{ paddingLeft: "20px", paddingRight: "10px" }}>
                    <Box
                        sx={{
                            p: 0,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            height: "89%",
                        }}
                    >
                        <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(2, 1fr)', gap: '10px' }}>
                            {displayMedia.map((media, i) => (
                                <div
                                    key={i}
                                    style={{
                                        position: 'relative',
                                        cursor: 'pointer',
                                        overflow: 'hidden',
                                        height: '150px',
                                        borderRadius: '4px',
                                    }}
                                    onClick={() => {
                                        handleMediaChange(allMedia.indexOf(media));
                                        setCarouselOpen(true);
                                    }}
                                >
                                    {media.endsWith('.mp4') ? (
                                        <video
                                            src={media}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                display: 'block',
                                            }}
                                            controls
                                            muted
                                        />
                                    ) : (
                                        <img
                                            src={media}
                                            style={{
                                                width: '100%',
                                                height: '100%',
                                                objectFit: 'cover',
                                                display: 'block',
                                            }}
                                            alt={`Thumbnail ${i + 1}`}
                                        />
                                    )}
                                    {additionalCount > 0 && i === displayMedia.length - 1 && (
                                        <div
                                            style={{
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                                display: 'flex',
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                                color: '#fff',
                                                fontSize: '24px',
                                                fontWeight: 'bold',
                                                borderRadius: '4px',
                                                cursor: 'pointer',
                                                zIndex: 10,
                                            }}
                                            onClick={() => setCarouselOpen(true)}
                                        >
                                            {`+${additionalCount}`}
                                        </div>
                                    )}
                                </div>
                            ))}
                        </Box>
                    </Box>
                </Grid>

                {carouselOpen && (
                    <Box
                        sx={{
                            position: 'fixed',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            backgroundColor: 'rgba(0, 0, 0, 0.8)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 1300,
                            overflow: 'hidden',
                        }}
                    >
                        <Carousel
                            index={currentIndex}
                            onChange={(index) => handleMediaChange(index)}
                            autoPlay={false}
                            animation="slide"
                            navButtonsAlwaysVisible
                            navButtonsProps={{
                                style: {
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                    color: '#fff',
                                },
                            }}
                            indicators={false}
                            sx={{
                                width: '80vw',
                                height: '80vh',
                                position: 'relative',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            {allMedia.map((media, i) => (
                                <div key={i} style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                    {media.endsWith('.mp4') ? (
                                        <video
                                            src={media}
                                            alt={`Carousel ${i + 1}`}
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'contain',
                                            }}
                                            controls
                                            autoPlay
                                            muted
                                            ref={i === currentIndex ? videoRef : null}
                                        />
                                    ) : (
                                        <img
                                            src={media}
                                            alt={`Carousel ${i + 1}`}
                                            style={{
                                                maxWidth: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'contain',
                                            }}
                                        />
                                    )}
                                </div>
                            ))}
                        </Carousel>
                        <IconButton
                            sx={{
                                position: 'absolute',
                                top: 10,
                                right: 10,
                                backgroundColor: 'rgba(0, 0, 0, 0.5)',
                                color: '#fff',
                                '&:hover': {
                                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                                },
                                zIndex: 10,
                            }}
                            onClick={handleCloseCarousel}
                        >
                            <MdClose size={30} />
                        </IconButton>
                        
                    </Box>
                )}
              
            </Grid>
     
                <Box
                        sx={{
                            p: 2,
                            bgcolor: 'background.paper',
                            borderRadius: 1,
                            marginTop: 0,
                            textAlign: 'center',
                            width: '100%',
                            position: 'relative',
                            zIndex: 100,
                        }}
                    >
                        <Typography variant="h6" gutterBottom>
                            Description
                        </Typography>
                        <Typography variant="body1">
                            {userData.description}
                        </Typography>
                    </Box>

              <Dialog open={openModal} onClose={handleCloseModal}>
                <DialogTitle>Contact</DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    id="contactMessage"
                    label="Your Message"
                    type="text"
                    fullWidth
                    variant="standard"
                    multiline
                    rows={5}
                    className="text-area"
                    value={contactMessage}
                    onChange={(e) => setContactMessage(e.target.value)}
                />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button onClick={handleSendMessage}>Send</Button>
                </DialogActions>
            </Dialog>
            <Footer />
        </div>
    );
};

export default ImagePage;
