import React, { useState, useEffect } from 'react';
import Header from '../components/Header';
import { useNavigate } from 'react-router-dom';
import Footer from "../components/Footer";
import { Container, Grid, Box, List, ListItem, ListItemIcon, ListItemText, Typography, Divider } from "@mui/material";
import Homelistcard from "../components/Homelistcard";
import { FiShoppingCart } from "react-icons/fi";
import HomeIcon from '@mui/icons-material/Home'; 
import { MdSell } from "react-icons/md";
import SettingsIcon from '@mui/icons-material/Settings';
import '../styles/buying.css';
import { FiMessageCircle } from "react-icons/fi";

const Buying = () => {
  const [activeMenu, setActiveMenu] = useState("Buying");
  const [homes, setHomes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchHomes = async () => {
      try {
        const response = await fetch('http://localhost:9000/api/getbuying');
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();


      

    

        // Ensure `data` is an array of home objects with the correct image URL field
        const updatedHomes = data.map(home => ({
          ...home,
          imageUrl: home.images && home.images.length > 0 ? `http://localhost:9000/${home.images[0]}` : null,
        }));

        // Set the homes data
        setHomes(updatedHomes);
      } catch (error) {
        console.error("Error fetching properties:", error);
      }
    };

    fetchHomes();
  }, []);

  const handleMenuClick = (menu) => {
    if (activeMenu === menu) return; // Avoid unnecessary navigation

    setActiveMenu(menu);
    if (menu === "Buying") {
      navigate('/buying');
    } else if (menu === "Renting") {
      navigate('/rent');
    } else if (menu === "Selling") {
      navigate('/selling');
    }
    else if (menu === "Profile Settings") {
      navigate('/profile');

    }
    else if (menu === "Message") {
      navigate('/message');
    }
  };
  return (
    <div>
      <Header />
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" }, minHeight: "100vh" }}>
        <Box
          sx={{
            width: { xs: "100%", md: "321px" },
            borderRight: { md: "1px solid #ddd" },
            padding: "20px",
            boxSizing: 'border-box',
          }}
        >
          <Typography variant="h6" gutterBottom>Menu</Typography>
          <List
            component="nav"
            sx={{
              padding: "0",
              backgroundColor: "#fff",
              boxShadow: "0 2px 4px 0 rgba(0,0,0,.1)",
            }}
          >
            <ListItem button
  className={`menu-item ${activeMenu === "Buying" ? "active" : ""}`}
  onClick={() => handleMenuClick("Buying")}
              >
              <ListItemIcon>
              <FiShoppingCart />
              </ListItemIcon>
              <ListItemText
                primary="Buying"
                primaryTypographyProps={{
                  sx: {
                    color: activeMenu === "Buying" ? "#000" : "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Renting" ? "active" : ""}`}
  onClick={() => handleMenuClick("Renting")}
>
              <ListItemIcon>
              <HomeIcon />
              </ListItemIcon>
              <ListItemText
                primary="Renting"
                primaryTypographyProps={{
                  sx: {
                    color: activeMenu === "Rent" ? "#000" : "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem button
  className={`menu-item ${activeMenu === "Selling" ? "active" : ""}`}
  onClick={() => handleMenuClick("Selling")}
>
              <ListItemIcon>
              <MdSell />
              </ListItemIcon>
              <ListItemText
                primary="Selling"
                primaryTypographyProps={{
                  sx: {
                    color: activeMenu === "Selling" ? "#000" : "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Profile Settings" ? "active" : ""}`}
  onClick={() => handleMenuClick("Profile Settings")}
>
              <ListItemIcon>
                <SettingsIcon />
              </ListItemIcon>
              <ListItemText
                primary="Profile Settings"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: "10px 0",
                  },
                }}
              />
            </ListItem>
            <Divider />
            <Divider />
            <ListItem  button
  className={`menu-item ${activeMenu === "Message" ? "active" : ""}`}
  onClick={() => handleMenuClick("Message")}
>
              <ListItemIcon>
              <FiMessageCircle />
              </ListItemIcon>
              <ListItemText
                primary="Message"
                primaryTypographyProps={{
                  sx: {
                    color: "#787674",
                    fontWeight: "600",
                    marginBottom: "0px",
                    padding: " 10px 0",
                  },
                }}
              />
            </ListItem>
          </List>
        </Box>

        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Container className="homelist1">
            <Typography variant="h4" gutterBottom>Buying</Typography>
          </Container>
          <Container>
          <Grid container spacing={3}>
  {homes.length > 0 ? (
    homes.map((home, index) => (
      <Grid className='icons' item xs={12} sm={6} md={4} lg={3} key={index}>
       <Homelistcard
                        imageUrl={home.imageUrl}
                        heading={home.title}
                        description={home.Address}
                        Bedrooms={home.Bedrooms}
                        Washrooms={home.Washrooms}
                        Parkings={home.Parkings}
                        id={home._id} // Pass the ID as a prop
                    />
      </Grid>
    ))
  ) : (
    <Typography variant="h6">Loading...</Typography>
  )}
</Grid>
          </Container>
        </Box>
      </Box>
      <Footer />
    </div>
  );
};

export default Buying;
