import React, { useContext, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/Signup.css";
import "../adminstyle/adminsignin.css";
import { multiStepContextAdmin } from "../../StepContextAdmin";

const Adminverify = () => {
  var inputs = document.querySelectorAll(".checkcode");
  inputs.forEach(function (input, index) {
    input.addEventListener("input", function () {
      if (index < inputs.length - 1) {
        inputs[index + 1].focus();
      }
    });
  });

  const { setCurrentStep, userData, setUserData } = useContext(
    multiStepContextAdmin
  );
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const handleVerify = () => {
    if (
      userData.firstdigit === undefined ||
      userData.firstdigit === "" ||
      userData.seconddigit === undefined ||
      userData.seconddigit === "" ||
      userData.thirddigit === undefined ||
      userData.thirddigit === "" ||
      userData.fourthdigit === undefined ||
      userData.fourthdigit === "" ||
      userData.fifthdigit === undefined ||
      userData.fifthdigit === "" ||
      userData.sixthdigit === undefined ||
      userData.sixthdigit === ""
    ) {
      setError("Please Enter the valid Otp Code Number..!");
    } else {
      setError("");

      axios.post("http://localhost:9000/api/admin/verifyotp", userData).then((response) => {
        if (response.status === 200) {
          toast.success("Admin Account Verication Successfully Done..", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          });

          setTimeout(() => {
            navigate("/admindashboard"); // Proceed to the next step
          }, 3000);
        }

        if (response.status === 201) {
          setError("OTP doesnot matched please, enter  valid code.");
        }
      });
    }
  };

  return (
    <div id="signin">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>

        <Grid container spacing={2}>
          <Grid item xs={12} md={3}></Grid>
          <Grid item xs={12} md={6}>
            <div className="signup">
              <h3 style={{ marginBottom: "50px" }}>Enter Verification Code</h3>
              <ToastContainer />
              <div className="otp-field" style={{ textAlign: "center" }}>
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="firstdigit"
                  value={userData.firstdigit}
                  name="firstdigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      firstdigit: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="seconddigit"
                  value={userData.seconddigit}
                  name="seconddigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      seconddigit: e.target.value,
                    }))
                  }
                />
                <input
                  class="space"
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="thirddigit"
                  value={userData.thirddigit}
                  name="thirddigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      thirddigit: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="fourthdigit"
                  value={userData.fourthdigit}
                  name="fourthdigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      fourthdigit: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="fifthdigit"
                  value={userData.fifthdigit}
                  name="fifthdigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      fifthdigit: e.target.value,
                    }))
                  }
                />
                <input
                  type="text"
                  maxLength="1"
                  className="checkcode"
                  id="sixthdigit"
                  value={userData.sixthdigit}
                  name="sixthdigit"
                  onChange={(e) =>
                    setUserData((prevUserData) => ({
                      ...prevUserData,
                      sixthdigit: e.target.value,
                    }))
                  }
                />
              </div>

              {error && (
                <p className="error" style={{ color: "red" }}>
                  {error}
                </p>
              )}

              <Button
                id="sbbtnv"
                type="submit"
                fullWidth
                variant="contained"
                onClick={handleVerify}
              >
                Verify
              </Button>
            </div>
          </Grid>
          <Grid item xs={12} md={3}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Adminverify;
