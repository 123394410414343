import React, { useState } from "react";
import {
  CssBaseline,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Box,
  Button,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Sidebar from "../admincomponents/Sidebar";
import LatestPropertyForm from "../admincomponents/LatestPropertyForm";
import InternationalHomesForm from "../admincomponents/InternationalHomesForm";
import DreamHomesForm from "../admincomponents/DreamHomesForm";
import NewDevelopmentsForm from "../admincomponents/NewDevelopmentsForm";
import Allusers from "../admincomponents/Allusers";

const Admindashboard = () => {
  const [open, setOpen] = useState(true);
  const [activeMenu, setActiveMenu] = useState("Latest Property");

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleLogout = () => {
    console.log("Logout clicked");
  };

  const handleMenuClick = (menu) => {
    setActiveMenu(menu);
  };

  const renderContent = () => {
    switch (activeMenu) {
      case "Latest Property":
        return <LatestPropertyForm />;
      case "International Homes":
        return <InternationalHomesForm />;
      case "Dream Homes":
        return <DreamHomesForm />;
      case "New Developments":
        return <NewDevelopmentsForm />;
      case "All Users": // Corrected case statement
        return <Allusers />;
      case "Logout":
        handleLogout();
        return null;
      default:
        return null;
    }
  };

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "#0f2481",
          marginBottom: "30px",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={toggleDrawer}
            sx={{ mr: 2 }}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" noWrap sx={{ flexGrow: 1 }}>
            Admin Dashboard
          </Typography>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>
      <Sidebar
        open={open}
        toggleDrawer={toggleDrawer}
        activeMenu={activeMenu}
        handleMenuClick={handleMenuClick}
      />
      <Box component="main" sx={{ flexGrow: 1, p: 3, width: "100%" }}>
        <Toolbar />
        <Box sx={{ margin: "0px auto", width: "100%" }}>{renderContent()}</Box>
      </Box>
    </Box>
  );
};

export default Admindashboard;
