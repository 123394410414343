import React, { useContext, useState } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../styles/Signup.css";
import "../adminstyle/adminsignin.css";
import { multiStepContextAdmin } from "../../StepContextAdmin";

const Adminquestions = () => {
  const { setCurrentStep, userData, setUserData } = useContext(
    multiStepContextAdmin
  );
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const questions = [
    "What is your Favourite Food?",
    "What is your Favourite Drink?",
    "What is your Favourite Colour?",
    "What is your Favourite Sport?",
    "Who is your Favourite Super Hero?",
    "What Year would you like to be Born?",
    "What Country of the World would you like to Live?",
    "What Planet in the Cosmos would you like to Visit?",
    "What Super Powers would you like to Have?",
    "If you had 1 Wish, what Wish would that Be?",
  ];

  const validateQuestions = async () => {
    // Filter the questions that have answers
    const filteredUserData = Object.entries(userData).reduce(
      (acc, [key, value]) => {
        if (value.answer) {
          acc[key] = value;
        }
        return acc;
      },
      {}
    );

    // Count how many questions are answered
    const answeredQuestions = Object.values(filteredUserData).length;

    if (answeredQuestions > 5) {
      setError("You cannot answer more than 5 questions.");
    } else if (answeredQuestions < 5) {
      setError(
        "Please answer at least five questions to proceed. Or click on the Skip button to create your own questions."
      );
    } else {
      setError("");
      try {
        const response = await fetch("http://localhost:9000/api/admin/securityquestion", {
          method: 'POST',
          headers: {
            'Content-type': 'application/json',
          },
          body: JSON.stringify(filteredUserData),
        });

        if (response.status === 200) {
          toast.success(
            "Signup Successful.. A Verification Code to verify your Account has been sent to your registered email address.!!",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );

          setTimeout(() => {
            setCurrentStep(4); // Proceed to the next step
          }, 3000);
        } else {
          toast.error(
            "Answers do not match",
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      } catch (err) {
        if (err.response) {
          const status = err.response.status;

          if (status === 409) {
            toast.error("Email is already used.. Please try again.", {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });

            setTimeout(() => {
              navigate("/signup");
            }, 5000);
          } else {
            // Log other errors to the console
            console.log(err);
          }
        }
      }
    }
  };

  const handleInputChange = (e, questionLabel) => {
    // Count the number of already answered questions
    const answeredQuestionsCount = Object.values(userData).filter(
      (data) => data.answer
    ).length;

    // Check if the current field already has a value or not
    const isUpdatingExistingAnswer = !!userData[e.target.name]?.answer;

    // Prevent adding more than 5 answers unless updating an existing answer
    if (answeredQuestionsCount < 5 || isUpdatingExistingAnswer) {
      setUserData((prevUserData) => ({
        ...prevUserData,
        [e.target.name]: { question: questionLabel, answer: e.target.value },
      }));
    } else {
      setError("You cannot answer more than 5 questions.");
    }
  };

  return (
    <div id="signup">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>

        <ToastContainer />

        <Grid container spacing={2}>
          <Grid item xs={12} md={1}></Grid>
          <Grid item xs={12} md={10}>
            <div className="quespg">
              <h3>Security Questions</h3>
              <p>Answer At least Five Questions among these 10.</p>
              <Grid container spacing={2}>
                {questions.map((question, index) => (
                  <Grid item xs={12} md={6} key={index}>
                    <TextField
                      id={`answer${index + 1}`}
                      label={question}
                      variant="outlined"
                      margin="normal"
                      name={`answer${index + 1}`}
                      fullWidth
                      value={userData[`answer${index + 1}`]?.answer || ""}
                      onChange={(e) => handleInputChange(e, question)}
                    />
                  </Grid>
                ))}
              </Grid>

              {error && (
                <p className="error" style={{ color: "red" }}>
                  {error}
                </p>
              )}
              <div className="back_btn">
                <Button
                  id="sbbtn1"
                  variant="contained"
                  onClick={() => setCurrentStep(1)}
                >
                  Back
                </Button>

                <Button
                  id="sbbtn1"
                  variant="contained"
                  onClick={validateQuestions}
                >
                  Submit
                </Button>

                <Button variant="outlined" onClick={() => setCurrentStep(3)}>
                  Skip
                </Button>
              </div>
            </div>
          </Grid>

          <Grid item xs={12} md={1}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default Adminquestions;
