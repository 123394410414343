import React, { useContext } from "react";
import axios from "axios";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import { Link, useNavigate } from "react-router-dom";
import logo from "../images/logo.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../styles/Signup.css";
import { useState, useEffect } from "react";
import { multiStepContext } from "../StepContext";

const CustomQuestions = () => {
  const navigate = useNavigate();
  const { setStep, userData, setUserData } = useContext(multiStepContext);
  const [error, setError] = useState("");

  const validateCustomQuestions = () => {
    // Count how many questions are answered
    const answeredQuestions = Object.values(userData).filter(
      (question) => question !== undefined && question !== ""
    ).length;

    if (answeredQuestions >= 16) {
      setError("");
      fetch("http://localhost:9000/api/customquestion", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userData),
      })
        .then((response) => {
          if (response.status === 200) {
            toast.success(
              "Signup Successful.. A Verfication Code to verify your Account has been sent to your registered email address.!!",
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );

            setTimeout(() => {
              setStep(4); // Proceed to the next step
            }, 3000);
          }
        })
        .catch((err) => {
          if (err.response) {
            const status = err.response.status;

            if (status === 409) {
              toast.error("Email is already used.. Please try again.", {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              });

              setTimeout(() => {
                navigate("/signup");
              }, 5000);
            } else {
              // Log other errors to the console
              console.log(err);
            }
          }
        });
    } else {
      setError("All fields are required...!!");
    }
  };

  return (
    <div id="signup">
      <Container className="signin_logo" id="sign_id" maxWidth="lg">
        <Link to="/" className="lnks">
          <img src={logo} alt="Logo" />
        </Link>
        <ToastContainer />
        <Grid container spacing={2}>
          <Grid item xs={12} md={2}></Grid>
          <Grid item xs={12} md={8}>
            <div className="signup">
              <h3>Create Custom Security Questions</h3>
              <p>Create three Question & Answers</p>

              {[1, 2, 3, 4, 5].map((index) => (
                <React.Fragment key={index}>
                  <div style={{ display: "flex" }}>
                    <TextField
                      id={`ques${index}`}
                      label={`Write your question ${index}?`}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      style={{ marginRight: "10px" }}
                      value={userData[`ques${index}`]}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          [`ques${index}`]: e.target.value,
                        }))
                      }
                    />
                    <TextField
                      id={`ans${index}`}
                      label={`Write your answer ${index}?`}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      value={userData[`ans${index}`]}
                      onChange={(e) =>
                        setUserData((prevUserData) => ({
                          ...prevUserData,
                          [`ans${index}`]: e.target.value,
                        }))
                      }
                    />
                  </div>
                </React.Fragment>
              ))}

              {error && (
                <p className="error" style={{ color: "red" }}>
                  {error}
                </p>
              )}

              <div className="back_btn">
                <Button id="sbbtn2" variant="contained" onClick={() => setStep(2)}>
                  Back
                </Button>
                <Button id="sbbtn2" variant="contained" onClick={validateCustomQuestions}>
                  Submit
                </Button>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={2}></Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default CustomQuestions;
